@charset "utf-8";
@import "./variables";
@import "./mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.rubric-wrapper {
    @include flex-wrap;

    > div {
        width: calc(100% / 4 - 2.3rem);
        margin: 0  2.3rem 2.3rem  0;

        &:nth-child(4n) {
            width: calc(100% / 4 - 2.3rem);
            margin: 0 0 2.3rem  0;
        }
    }
}

.rubric__item {
    position: relative;
    display: block;
    border-radius: .4rem;

    img {
        border-radius: .4rem;
    }
}

.rubric-top {
    margin-bottom: 1.5rem;
}

.rubric__title {
    color: $text-color;
    font-weight: 700;
    text-transform: uppercase;
    font-size: .75rem;
    line-height: 1.4;
    margin: .6rem 0 0 0;
    letter-spacing: 0.02em;
    .rubric-wrapper &, .main-rubric & {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
	.nav-catalog & { 
	 	-webkit-line-clamp: 4;
         display: -webkit-box;
         -webkit-box-orient: vertical;
         white-space: inherit;
         line-height: 1.2;
		 letter-spacing: normal;
	}
    @include media-breakpoint-up(lg) {
        font-size: 1.07rem;
        color: $grey-color;
        display: block;
        position: relative;
        padding: .3rem 2rem .3rem 0;
        text-transform: inherit;
        @include hover-focus {
            color: $darkblue-color;
        }

        .active & {
            color: $blue-color;
        }

        .nav-catalog & {
			-webkit-line-clamp: 3;
            margin: .3rem 0 0 0;
            font-size: .95rem;
            font-weight: 400;
            padding: 0;
        }

        .rubric-wrapper &, .main-rubric &, .rubric-top & {
            @include flex-justify-start;
            align-items: center;
            white-space: inherit;
            color: $white-color;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            font-size: .9rem;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 0 0 .4rem .4rem;
            background: rgba(0, 0, 0, 0.38);
            border-left: $blue-color solid .46rem;
            padding: .75rem 3rem .75rem .8rem;
            min-height: 4rem;

            &:after {
                @include after-before-abs;
                top: 50%;
                right: 1.2rem;
                margin: -.4rem 0 0 0;
                width: 14px;
                height: 11px;
                background: url("../svg/forward_w.svg")  center center  no-repeat;
            }
        }

        .main-rubric .rubric__item:hover & {
            background: rgba(0, 0, 0, 0.45);
            transition: all ease-out .2s;

            &:after {
                transition: all ease-out .2s;
                right: 1rem;
            }
        }
    }
}

.product__table,  .product__list {
    margin: 0 -1rem;
    background: $lightgrey-color;
    @include media-breakpoint-up(md) {
        background: $white-color;
        border: none;
    }
}

.product__table {
    @include flex-wrap;
    text-align: center;
    padding-bottom: 1rem;
    @include media-breakpoint-up(md) {
        padding: 0;
        margin: 0 0 0 -1.5rem;
    }
}

.product__list {
    padding: 1rem 0 .01rem 0;
    @include media-breakpoint-up(md) {
        padding: 0;
        margin: 0;
    }
}

.product__item {
    background: $white-color;
    border: $lightgrey-color-2 solid 1px;
    border-radius: .4rem;
    padding: 0 0 .8rem 0;

    .product__table & {
        flex-direction: column;
        display: flex;
        width: calc(100% / 2 - 1.5rem);
        margin: 1rem .5rem 0 1rem;

        &:nth-child(2n) {
            margin: 1rem 1rem 0 .5rem;
        }
    }

    .product__list & {
        display: flex;
        margin: 0 1rem 1rem 1rem;
        padding: 0;
    }

    .slider-block & {
        flex-direction: column;
        display: flex;
    }
    @include hover-focus {
        transition: all ease-out .4s;
        border-color: $lightgrey-color-5;
        z-index: 10;
    }

    @include media-breakpoint-up(md) {
        border: $lightgrey-color-2 solid 1px;
        border-radius: .4rem;

        .product__table & {
            width: calc(100% / 3 - 1.5rem);
            padding: 0 0 .8rem 0;
            margin: 0 0  1.5rem 1.5rem;

            &:nth-child(2n), &:nth-child(3n) {
                margin: 0 0 1.5rem 1.5rem;
            }
        }

        .product__search & {
            width: calc(100% / 4 - 1.5rem);
            padding: 0 0 .8rem 0;
            margin: 0 0  1.5rem 1.5rem;

            &:nth-child(2n), &:nth-child(3n) {
                margin: 0 0 1.5rem 1.5rem;
            }
        }

        .product__list & {
            margin: 0 0 1.5rem 0;
            padding: 0;
        }

        .slider-block & {
            padding: 0 0 .8rem 0;
        }
    }
    @include media-breakpoint-up(lg) {

        .product__table & {
            max-height: 357px;
            @include hover-focus {

                & .product__buy {
                    opacity: 1;
                }
            }
        }
    }
    @include media-breakpoint-up(xl) {

        .product__table & {
            max-height: 405px;
        }

        .product__list & {
            margin-bottom: 1.5rem;
        }
    }
    @include media-breakpoint-up(xxl) {

        .product__search & {
            width: calc(100% / 5 - 1.5rem);
        }
    }
    @include media-breakpoint-up(xlg) {

        .product__table & {
            width: calc(100% / 4 - 1.5rem);
            max-height: 407px;
        }

        .product__search & {
            width: calc(100% / 5 - 1.5rem);
        }
    }
}

.product__fill {
    @include flex-between;
    flex-direction: column;
    flex: 1 1 auto;
    padding: .3rem .8rem .5rem .8rem;
    @include media-breakpoint-up(lg) {
        padding: 1rem;
    }
    @include media-breakpoint-up(xl) {
        padding: 1.1rem;
    }
}

.product__img {
    position: relative;

    img {
        border-radius: .4rem .4rem 0 0;
    }

    a {
        @include flex-justify-items-center;
        max-width: 100%;
    }

    .product__list & {
        min-width: 90px;
        max-width: 90px;
        @include flex-justify-items-center;

        img {
            border-radius: .4rem;
        }
    }
    @include media-breakpoint-up(xs360) {

        .product__list & {
            min-width: 120px;
            max-width: 120px;
        }
    }
    @include media-breakpoint-up(md) {

        .product__list & {
            max-width: 120px;
        }
    }
    @include media-breakpoint-up(lg) {

        .product__list & {
            min-width: 150px;
        }
    }
    @include media-breakpoint-up(xl) {

        .product__list & {
            min-width: 180px;
        }
    }
}

.product__discount {
    font-weight: 700;
    font-size: 1rem;
    position: absolute;
    top: .5rem;
    left: .5rem;
    border-radius: .2rem;
    background: $red-color;
    padding: 0 .3rem;
    color: $white-color;

    .slider-list & {
        top: 0;
        left: 0;
    }
    @include media-breakpoint-up(md) {
        top: .95rem;
        left: .95rem;
        padding: .15rem .4rem;

        .slider-list & {
            top: .95rem;
            left: .95rem;
        }

        .compare & {
            top: .5rem;
            left: .5rem;
        }
    }
}

.product__simile {
    background: url("../svg/simile.svg") center no-repeat;
    background-size: 22px 18px;
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: 0;
    right: 0;

    &.active {
        background-image: url("../svg/simile-actitve.svg");
    }

    .product__list .product__img  & {
        display: block;
    }
    @include media-breakpoint-up(md) {

        .product__list .product__img  & {
            display: none;
        }

        .product__list .product__buy & {
            position: relative;
            margin-left: 1rem;
            top: inherit;
            right: inherit;
            width: 2rem;
            height: 2rem;
            align-self: center;
        }
    }
    @include media-breakpoint-up(xl) {
        background-size: 30px 25px;
        width: 3.5rem;
        height: 3.5rem;
    }
}

.product__block {
    display: block;
    width: 100%;
    color: $darkgrey-color;
    padding: .5rem .2rem 0 .2rem;
    @include hover-focus {
        color: $darkgrey-color;
    }

    .product__list & {
        flex: 1 1 auto;
        padding: 0;
    }
    @include media-breakpoint-up(lg) {
        padding: .5rem .5rem 0 .5rem;
    }
    @include media-breakpoint-up(xxl) {
        padding: .5rem 1rem 0 1rem;
    }
}

.product__title {
    font-size: .95rem;
    line-height: 1.3;
    margin-bottom: .6rem;
    color: $text-color;
    overflow: hidden;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    .product__item:hover & {
        color: $blue-color;
    }

    .slider-block & {
        height: 3.705rem;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: .4rem;
        -webkit-line-clamp: 3;
        height: 3.705rem;

        .product__table & {
            margin-bottom: .6rem;
        }

        .product__list & {
            height: 1.236rem;
            -webkit-line-clamp: 1;
            margin-bottom: .6rem;
        }

        .slider-block & {
            height: 3.78rem;
        }
    }
    @include media-breakpoint-up(xl) {

        .product__list & {
            font-size: 1rem;
            height: 1.3rem;
        }
    }
}

.product__anons {
    display: none;
    overflow: hidden;
    color: $grey-color;
    line-height: 1.5;
    font-size: .9rem;
    -webkit-box-orient: vertical;
    @include media-breakpoint-up(md) {
        display: -webkit-box;
        -webkit-line-clamp: 1;
    }
    @include media-breakpoint-up(xl) {
        font-size: 1rem;
        -webkit-line-clamp: 2;
    }
}

.product__brand {
    font-size: .87rem;
    line-height: .9;
    margin-bottom: .7rem;
    color: $grey-color;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    span {
        display: inline-block;

        & + span {
            border-left: 1px solid $lightgrey-color-2;
            padding-left: .4rem;
            margin-left: .4rem;
        }
    }
    @include media-breakpoint-up(lg) {
        letter-spacing: normal;
        line-height: .9;

        span {

            & + span {
                padding-left: .7rem;
                margin-left: .7rem;
            }
        }
    }
    @include media-breakpoint-up(xl) {
        margin-bottom: .9rem;
    }
}

.product__status {
    font-size: .9rem;
    line-height: 1.1;
    color: $grey-color;
    font-weight: 400;

    &:before {
        @include after-before-inline;
        width: 10px;
        height: 13px;
        background: url("../svg/instock.svg");
        margin: -1px .5rem 0 0;
        vertical-align: middle;
    }

    &.outstock {

        &:before {
            background-image: url("../svg/outofstock.svg");
        }
    }

    &.clarify {

        &:before {
            background-image: url("../svg/clarify.svg");
        }
    }

    .product__list .product__price & {
        display: flex;
        margin: 0 0 0 auto;
    }

    .product__table & {
        margin-bottom: .4rem;
    }

    .card__block &, .card__data & {
        font-size: 1rem;
        line-height: 1.6;
        color: $grey-color;
    }
    @include media-breakpoint-up(md) {

        .product__list  .product__buy-bottom & {
            align-self: center;
            margin: 0 0 0 auto;
        }

        .product__list .product__price & {
            display: none;
        }
    }
    @include media-breakpoint-up(lg) {

        .product__list & {
            margin-bottom: 0;
        }
    }
}

.product__price {
    align-self: center;
    align-items: center;
    justify-content: center;
    color: $text-color;
    font-weight: 700;
    line-height: 1.1;

    del {
        font-weight: 400;
        font-size: .85rem;
        color: $grey-color;
        margin-right: .5rem;
    }

    .product__list & {
        @include flex-justify-start;
        margin: 0 0 .6rem 0;
    }

    .slider-block & {
        line-height: 1.1;

        del {
            font-size: .8rem;
            margin-right: .3rem;
        }
    }
    @include media-breakpoint-up(md) {

        .product__list & {
            margin: 0;
        }
    }
    @include media-breakpoint-up(lg) {
        font-size: 1.3rem;

        del {
            font-size: .9rem;
            margin-right: .5rem;
        }
    }
}

.product__buy {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: .6rem .8rem 0 .8rem;
    margin-top: auto;

    .product__list & {
        padding: 0;
    }
    @include media-breakpoint-up(md) {

        .product__list & {
            display: flex;
            padding: 0;
        }
    }
    @include media-breakpoint-up(lg) {

        .product__list & {
            padding: .7rem 0 0 0;
        }

        .product__table & {
            display: flex;
            transition: all ease-out .2s;
            opacity: 0;
            margin: 0 -1px;
            background: $white-color;
            border: $lightgrey-color-5 solid 1px;
            border-top: 0;
            border-radius: 0 0 .4rem  .4rem;
            padding-bottom: .8rem;
            z-index: 100;
        }

        .slider-block & {
            display: flex;
        }
    }
}

.product__buy-top {
    display: flex;
    @include media-breakpoint-up(xl) {
        display: inline-flex;
    }
}

.product__buy-bottom {
    display: none;
    @include media-breakpoint-up(md) {
        display: inline-flex;
        flex: 1 1 auto;
    }
}

.order-button {
    @include flex-justify-items-center;
    width: 100%;
    border-radius: .2rem;
    text-transform: uppercase;
    font-size: .7rem;
    border: $lightgrey-color-5 solid 1px;
    color: $grey-color;
    padding: .4rem .2rem;
    @include hover-focus {
        color: $text-color;
        background: $lightgrey-color-2;
        border: $lightgrey-color-2  solid 1px;
    }

    .product__list & {
        width: calc(100% / 2 - .3rem);
        margin: 0 .3rem 0 0;
        max-width: 12rem;
    }

    .card__buy & {
        padding: .5rem 1rem;
        width: calc(100% / 2 - .4rem);
        margin: 0 .5rem 0 0;
        font-size: .8rem;
    }
    @include media-breakpoint-up(xs360) {

        .product__list & {
            width: calc(100% / 2 - .4rem);
            margin: 0 .5rem 0 0;
        }
    }
    @include media-breakpoint-up(md) {

        .product__list & {
            margin: 0 .4rem 0 1rem;
            width: 7rem;
        }
    }
    @include media-breakpoint-up(lg) {
        font-size: .65rem;

        .card__buy & {
            width: 11rem;
            max-width: 12rem;
            font-size: .8rem;
        }
    }
    @include media-breakpoint-up(xxl) {
        font-size: .6rem;
        padding: .4rem .2rem;
        width: calc(100% / 2 - .4rem);

        .slider-block &, .product__table & {
            margin: 0 .4rem 0 0;
        }

        .slider-block & {
            font-size: .6rem;
        }

        .product__list & {
            width: 10rem;
        }
    }
}

.basket-button {
    width: 100%;
    @include flex-justify-items-center;
    font-weight: 700;
    letter-spacing: 0.02em;
    color: $grey-color;
    border-radius: .2rem;
    text-transform: uppercase;
    font-size: .8rem;
    background: $yellow-color;
    border: $yellow-color solid 1px;
    padding: .4rem .2rem;
    @include hover-focus {
        border: $yellow-color  solid 1px;
        color: $text-color;
        background: $lightyellow-color;
    }

    .product__list & {
        width: calc(100% / 2 - .3rem);
        margin: 0 0 0 .3rem;
        max-width: 12rem;
    }

    .slider-block &, .product__table & {
        margin: .6rem 0 0 0;
    }

    .card__buy & {
        padding: .5rem 1rem;
        width: calc(100% / 2 - .4rem);
        margin: 0 0 0 .5rem;
        font-size: 1rem;
    }
    @include media-breakpoint-up(xs360) {

        .product__list & {
            width: calc(100% / 2 - .4rem);
            margin: 0 0 0 .4rem;
        }
    }
    @include media-breakpoint-up(md) {

        .product__list & {
            width: 7rem;
            margin: 0 0 0 .4rem;
        }
    }
    @include media-breakpoint-up(lg) {
        font-size: .8rem;

        .product__table & {
            margin: .4rem 0 0 0;
        }

        .card__buy & {
            width: 11rem;
            font-size: 1rem;
        }
    }
    @include media-breakpoint-up(xxl) {
        padding: .4rem .2rem;
        width: calc(100% / 2 - .4rem);

        .slider-block &, .product__table &, .product__list & {
            margin: 0 0 0 .4rem;
        }

        .product__list & {
            width: 10rem;
        }
    }
}

.similar-button {
    @include flex-justify-items-center;
    padding: .4rem 1rem;
    font-weight: 700;
    border-radius: .2rem;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-size: .8rem;
    background: $white-color;
    color: $blue-color;
    border: $blue-color solid 1px;
    width: 100%;
    @include hover-focus {
        color: $white-color;
        background: $blue-color;
        border: $blue-color solid 1px;
    }

    .product__list & {
        max-width: 10rem;
        padding: .4rem .5rem;
    }

    .product__table & {
        margin: 1rem 0 0 0;
        font-size: .75rem;
        padding: .4rem 0;
    }
    @include media-breakpoint-up(lg) {
        padding: .4rem 1rem;
        margin: 0;
        font-size: .8rem;

        .product__list & {
            width: 11rem;
        }
    }
}

.main-block {

    h2 {
        color: $grey-color;
        font-weight: 700;
        text-transform: uppercase;
    }
    @include media-breakpoint-up(lg) {

        h2 {
            padding-left: 2.5rem;
            position: relative;

            &:before {
                @include after-before-abs;
                top: 50%;
                left: 0;
                margin: -.85rem 0 0 0;
                width: 1.7rem;
                height: 1.7rem;
                background-position: left center;
                background-repeat: no-repeat;
            }
        }
    }
}

.more {
    color: $grey-color;
    font-size: .8rem;
    text-transform: uppercase;
    line-height: 22px;
    display: inline-block;
    padding-left: 2rem;
    background: url("../svg/expand.svg") left center no-repeat;

    &.opened {
        background-image: url("../svg/rollup.svg");
    }
    @include hover-focus {
        outline: none;
        color: $blue-color;
    }
}

.catalog-list__container {
    @include flex-wrap;
    padding: 0;
    margin: 0;
    width: 100%;
    line-height: 1.4;

    .catalog-list__content {
        width: calc(50% - .6rem);
        margin: 0 .6rem 1rem 0;

        &:nth-child(2n) {
            margin: 0 0 1rem .5rem;
        }

        & > a {
            color: $text-color;
            text-transform: uppercase;
            margin-bottom: .8rem;
            display: block;
            @include hover-focus {
                color: $blue-color;
            }
        }

        & > .catalog-list__content-items {
            padding: 0;
            margin: 0;
            line-height: 1.4;

            .catalog-list__item {
                display: block;
                margin-bottom: .6rem;
                @include square-marker;

                & > a {
                    color: $grey-color;
                    @include hover-focus {
                        color: $blue-color;
                    }

                    &.catalog-list__content-items-more {
                        color: $blue-color;
                        font-size: .8rem;
                        @include hover-focus {
                            color: $darkblue-color;
                        }
                    }
                }
            }

            .catalog-list__content-items-collapse {
                height: 0;
                overflow: hidden;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        margin: 0 -.75rem;

        .catalog-list__content {
            width: calc(100% / 5 - 1.5rem);
            margin: 0 .75rem 1.5rem .75rem;

            &:nth-child(2n) {
                margin: 0 .75rem 1.5rem .75rem;
            }
        }
    }
}

.catalog-img {
    margin-bottom: 1.3rem;
    border-radius: .4rem;

    img {
        border-radius: .4rem;
    }
}

.catalog-title {
    margin-bottom: 1rem;
    font-size: .9rem;
    color: $grey-color;
    font-weight: 700;
}

.card__wrap {
    @include media-breakpoint-up(lg) {
        display: flex;
    }
}

.card__img {
    @include media-breakpoint-up(lg) {
        width: 45%;
    }
}

.card__body {
    padding: 1rem 0 0 0;
    @include media-breakpoint-up(lg) {
        width: 55%;
        padding: 0 0 0 2rem;
    }
    @include media-breakpoint-up(xlg) {
        padding: 0 0 0 6rem;
    }
}

.card__simile {
    background: url("../svg/simile.svg") left center no-repeat;
    background-size: 22px 17px;
    padding-left: 2rem;
    color: $grey-color;
    @include hover-focus {
        color: $blue-color;
    }
}

.card__share {
    background: url("../svg/share.svg") left center no-repeat;
    background-size: 14px 17px;
    padding-left: 2rem;
    position: relative;
    color: $grey-color;

    .prompt {
        min-width: 160px;
    }
    @include hover-focus {
        color: $blue-color;
    }
    @include media-breakpoint-up(lg) {
        background-size: 17px 21px;
    }
}

.card__block {
    @include flex-wrap;
    justify-content: flex-start;
    align-items: center;
    line-height: 1.4;
    color: $grey-color;

    > div, > button {
        width: calc(100% / 2 - .3rem);
        margin: 0 .3rem .3rem 0;

        &:nth-child(2n) {
            margin: 0 0 .3rem .3rem;
            text-align: right;
        }

        span {
            color: $text-color;
        }
    }
    @include media-breakpoint-up(lg) {
        margin: 0 0 1rem 0;

        > div, > button, &:nth-child(2n) {
            width: inherit;
            margin: 0 2rem 0 0;

            &:last-child {
                margin: 0;
            }
        }
    }
}

.card__data {
    color: $grey-color;
    margin-bottom: 1rem;

    span {
        color: $text-color;
    }
    @include media-breakpoint-up(xl) {
        @include flex-justify-start;
        align-items: center;

        > div {
            margin-right: 1.5rem;
        }
    }
}

.card__price {
    color: $text-color;
    font-weight: 700;
    font-size: 1.6rem;
    margin: 0 0 .5rem 0;
    text-align: center;

    del {
        font-weight: 400;
        font-size: 1.1rem;
        color: $grey-color;
        margin-right: .3rem;
    }
    @include media-breakpoint-up(lg) {
        text-align: left;
        margin: 0 2rem 0 0;
    }
}

.card__buy {
    display: flex;
}

.card__detail-wrap {

    h3 {
        margin-bottom: .8rem;
    }
    @include media-breakpoint-up(lg) {
        margin-top: 2rem;

        h3 {
            margin-bottom: 1.2rem;
        }
    }
}

.card__detail-item {
    @include flex-between;
    margin-bottom: .5rem;

    > div:first-child {
        position: relative;
        flex: 1 1 auto;
        color: $grey-color;

        &:before {
            @include after-before-abs;
            bottom: 5px;
            left: 0;
            right: 0;
            height: 1px;
            background: url("../svg/dotter-grey.svg") bottom repeat-x;
        }

        span {
            position: relative;
            background: $white-color;
            z-index: 1;
            padding: 0 .3rem 0 0;
        }
    }

    > div:last-child {
        padding-left: .3rem;
    }
    @include media-breakpoint-up(xl) {

        > div {
            width: 50%;
        }
    }
}

.card__note {
    padding: 1.3rem 0;
    @include media-breakpoint-up(lg) {
        padding: 2rem 0;

        .container {
            display: flex;
        }
    }
}

.card__tabs {
    @include media-breakpoint-up(lg) {
        width: 65%;
    }
}

.card__docs {
    @include media-breakpoint-up(lg) {
        width: 35%;
        padding-left: 2rem;
    }
    @include media-breakpoint-up(xlg) {
        padding-left: 6rem;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        line-height: 1.3;

        li {
            padding-left: 2rem;
            position: relative;
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }

            &:after {
                @include after-before-abs;
                top: 0;
                left: 0;
                width: 18px;
                height: 18px;
                background: url("../svg/docs-icon.svg");
            }
        }
    }
}

.nav-tabs {
    color: $grey-color;
    font-weight: 700;
    border-bottom: $lightgrey-color-2 solid 1px;
    font-size: 1.1rem;

    .nav-item {
        margin-right: 1.5rem;

        &:last-child {
            margin: 0;
        }
    }

    .nav-link {
        color: $grey-color;
        border: none;
        line-height: 1.6;
        padding-bottom: .5rem;
        border-bottom: $lightgrey-color-2 solid 1px;

        &.active, .show & {
            color: $grey-color;
            background-color: transparent;
            border-bottom: $red-color solid 3px;
            margin-bottom: -3px;
        }
    }
    @include media-breakpoint-up(lg) {

        .nav-link {
            padding-bottom: 1.1rem;
        }
    }
    @include media-breakpoint-up(xl) {

        .nav-item {
            margin-right: 2rem;
            font-size: 1rem;
        }
    }
}

.tab-content {
    padding: 1rem 0;
    @include media-breakpoint-up(lg) {
        padding: 1.5rem 0 0 0;
    }
}

.brand-image {
    margin-top: -10px;
}
