@charset "utf-8";
@import "./variables";
@import "./mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.swiper-wrapper-container {
    position: relative;
}

.slider-button {
    position: absolute;
    z-index: 10;
    cursor: pointer;

    @include flex-justify-items-center;
    border: none;
    width: 15px;
    height: 12px;
    right: 0;
    background-position: center;
    background-repeat: no-repeat;

    @include hover-focus {
        opacity: 0.8;
    }

    @include media-breakpoint-up(lg) {
        border-radius: 50%;
        width: 3.5rem;
        height: 3.5rem;
        background-color: $lightgrey-color;
    }
}

.slider-button-prev {
    background-image: url("../svg/back.svg");

    @include media-breakpoint-up(lg) {
        right: 7.5rem;
    }
}

.slider-button-next {
    background-image: url("../svg/forward.svg");
}

.swiper-pagination {
    margin: 0.8rem -0.3rem 0;
    display: flex;
    position: relative;
}

.swiper-pagination-bullet {
    height: 2px;
    display: flex;
    flex: 1 1 auto !important;
    border-radius: 0.2rem;
    background: $lightgrey-color-2;
    opacity: 1;
    margin: 0 0.3rem;
}

.swiper-pagination-bullet-active {
    background: $blue-color;
    opacity: 1;
}

.main-banner {

    > .container {
        padding: 0;
    }

    .slider-button {
        display: none;
    }

    .swiper-pagination {
        margin-left: 0.7rem;
        margin-right: 0.7rem;
    }

    @include media-breakpoint-up(lg) {
        padding: 2.4rem 0 0;
        background: $lightgrey-color;

        & > .container {
            padding: 0 1rem;
        }

        .swiper-pagination {
            display: none;
        }

        .slider-button {
            display: block;
            background-color: $white-color;
            bottom: 2.8rem;
            right: 2.8rem;
        }

        & .slider-button-prev {
            right: 7rem;
        }
    }
}

.banner-slider {
    width: 100%;
    height: 100%;

    @include media-breakpoint-up(lg) {
        overflow: hidden;

        img {
            border-radius: 0.4rem;
        }
    }
}

.main-rubric {
    padding: 1.3rem 0;

    .slider-button {
        top: -2rem;
        background-color: $white-color;
    }

    .nav-slider-prev {
        display: none;
    }

    @include media-breakpoint-up(lg) {
        padding: 2.4rem 0;
        background: $lightgrey-color;

        .slider-button {
            top: -4.2rem;
        }

        .rubric-slider-prev {
            right: 4.5rem;
            display: flex;
        }

        h2 {
            padding-left: 0;

            &::before {
                display: none;
            }
        }
    }
}

.rubric-slider {
    margin: 0 -1rem 0 0;

    .swiper-slide {
        height: auto;
        width: 40%;
    }

    @include media-breakpoint-up(sm) {
        margin: 0;

        .swiper-slide {
            width: 28%;
        }
    }

    @include media-breakpoint-up(lg) {
        overflow: hidden;
        margin: 0;

        .slider-button {
            top: -4.2rem;
        }

        .rubric-slider-prev {
            right: 4.5rem;
            display: flex;
        }

        .swiper-slide {
            width: inherit;
        }

        .nav-catalog & {

            .swiper-slide {
                width: inherit;
            }
        }
    }
}

.nav-catalog {
    padding: 0 0 1.3rem;

    .slider-button {
        top: -2rem;
    }

    .nav-slider-prev, .nav-slider-next {
        display: none;
    }

    @include media-breakpoint-up(lg) {
        padding: 0 0 0.65rem;
        border-bottom: 1px solid #eaeaea;
        margin: 0 0 1.5rem;
        padding-bottom: 1.5rem;

        .slider-button {
            top: -4.2rem;
        }
		.nav-slider-next {
			display: flex;
		}
        .nav-slider-prev {
            right: 4.5rem;
            display: flex;
        }
    }
}

.nav-slider {
    margin: 0 -1rem 0 0;

    .swiper-slide {
        width: 28%;
    }

    @include media-breakpoint-up(sm) {
        margin: 0;
    }

    @include media-breakpoint-up(lg) {
        overflow: hidden;
    }
}

.main-brands {
    padding: 1.3rem 0;

    .slider-button {
        top: -2rem;
        background-color: $white-color;
    }

    .slider-button-prev {
        display: none;
    }

    .swiper-slide {
        filter: grayscale(100%);
        opacity: 0.6;

        @include hover-focus {
            transition: all ease-out 0.5s;
            filter: none;
            opacity: 1;
        }
    }

    & .swiper-wrapper-container {
        background-color: $white-color;
    }

    @include media-breakpoint-up(lg) {
        border-top: none;
        background-color: $lightgrey-color;
        padding: 2.4rem 0;

        .slider-button {
            top: -4.2rem;
        }

        .slider-button-prev {
            right: 4rem;
            display: flex;
        }

        h2 {

            &::before {
                background-image: url("../svg/popular-icon.svg");
            }
        }

        .swiper-wrapper-container {
            background-color: $white-color;
            padding: 2.4rem 3rem;
        }
    }
}

.brands-slider {
    margin: 0 -1rem 0 0;

    .swiper-slide {
        height: auto;
        width: 38%;
    }

    @include media-breakpoint-up(sm) {
        margin: 0;

        .swiper-slide {
            width: 28%;
        }
    }
}

.slider-block {

    .slider-button {
        top: -2rem;
    }

    .slider-button-prev {
        display: none;
    }

    .swiper-container {
        margin: 0 -1rem 0 0;
    }

    .swiper-slide {
        height: auto;
        width: 40%;
    }

    @include media-breakpoint-up(sm) {

        .swiper-container {
            margin: 0;
        }

        .swiper-slide {
            width: 28%;
        }
    }

    @include media-breakpoint-up(lg) {

        .swiper-container {
            margin: 0;
        }

        .slider-button {
            top: -4.2rem;
        }

        .slider-button-prev {
            right: 4.5rem;
            display: flex;
        }
    }
}

.main-discount {
    padding: 1.3rem 0;
    background: $lightgrey-color;

    @include media-breakpoint-up(lg) {
        padding: 2.4rem 0;
        background: $white-color;

        h2 {

            &::before {
                background-image: url("../svg/discount-icon.svg");
            }
        }
    }
}

.card__img__wrap {
    @include media-breakpoint-up(xl) {
        display: flex;
    }
}

.card__img-main {
    position: relative;

    img {
        border-radius: 0.4rem;
    }

    .swiper-slide {
        text-align: center;

        @include flex-justify-items-center;
    }

    .product__discount {
        top: 0.95rem;
        left: 0.95rem;
        font-size: 1.1rem;
        padding: 0.2rem 0.6rem;
    }

    @include media-breakpoint-up(xl) {
        width: calc(100% - 120px);
        margin-left: 30px;

        .card__img-main-pagination {
            display: none;
        }
    }
}

.card__img-main-slider {
    border: $lightgrey-color-2 solid 1px;
    border-radius: 0.4rem;
}

.card__img-small {
    width: 90px;
    height: 380px;
    overflow: hidden;
    display: none;
    flex-direction: column;

    .swiper-slide {
        border: $lightgrey-color-2 solid 1px;
        border-radius: 0.4rem;
        height: 100px;

        &-thumb-active {
            border: #bababa solid 1px;
        }

        a {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 30px;
            overflow: hidden;
        }
    }

    img {
        border-radius: 0.4rem;
    }

    .slider-button {
        width: 30px;
        height: 30px;
        background-color: transparent;
        border-radius: 0;
        left: 50%;
        margin-left: -15px;
        opacity: 0.2;
        position: relative;

        &:hover {
            opacity: 1;
        }
    }

    .slider-button-prev {
        background: url("../svg/menu-down.svg") top center no-repeat;
    }

    .slider-button-next {
        background: url("../svg/menu-up.svg") bottom center no-repeat;
    }

    .swiper-container {
        width: 100%;
        height: 100%;
    }

    @include media-breakpoint-up(xl) {
        display: flex;
    }

    @include media-breakpoint-up(xlg) {
        height: 510px;
    }
}

.card__img-small-slider {
    height: calc(380px - 60px);

    @include media-breakpoint-up(xlg) {
        height: calc(510px - 60px);
    }
}

.set-block {
    padding: 1.3rem 0 0;
    background: $lightgrey-color;

    @include media-breakpoint-up(lg) {
        background: $white-color;
        padding: 0.5rem 0 2rem;

        h2 {

            &::before {
                background-image: url("../svg/set-icon.svg");
            }
        }
    }
}

.similar-block {
    background: $lightgrey-color;
    padding: 1.6rem 0 1.3rem;

    @include media-breakpoint-up(lg) {
        padding: 2rem 0;

        .slider-button {
            background-color: $white-color;
        }

        h2 {

            &::before {
                background-image: url("../svg/simile-icon.svg");
            }
        }
    }
}
