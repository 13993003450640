@charset "utf-8";
@mixin hover() {
  &:hover { @content; }
}

@mixin hover-focus() {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin plain-hover-focus() {
  &,
  &:hover,
  &:focus {
    @content;
  }
}

@mixin hover-focus-active() {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}

@mixin square-marker() {
	padding-left:1rem;
	position:relative;
	&:before {
		@include after-before-abs;
		display:block;
    	width:.35rem;
		height:.35rem;
		background:$blue-color;
		left:0;
		top:.5rem;
	}
}
@mixin after-before-abs() {
	content: "";
	display:block;
	position:absolute;
}
@mixin after-before-inline() {
	content: "";
	display: inline-block;
}
@mixin flex-between() {
	display:flex;
	justify-content: space-between;
}
@mixin flex-justify-items-center() {
	display:flex;
	justify-content: center;
	align-items: center
}
@mixin flex-justify-self-center() {
	display:flex;
	justify-content: center;
	align-self: center
}
@mixin flex-wrap() {
	display: flex;
	flex-wrap: wrap;
}
@mixin flex-justify-start() {
	display: flex;
	justify-content: flex-start;
};
