@charset "utf-8";
@import "./variables";
@import "./mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

footer {
	background:$lightblue-color;
	position:relative;
	text-align:center;
	color:$grey-color;
	a {
		color:$grey-color;
	}
	p {
		margin-bottom:0
	}
	@include media-breakpoint-up(lg) {
		text-align:inherit;
	}
}
.footer__top {
	padding:1.3rem 0;
	@include media-breakpoint-up(lg) {
		padding:2.4rem 0;
		.container {
			@include flex-between;
		}
	}
}
.footer__logo {
	display:block;
	margin-bottom:1em;
	@include media-breakpoint-up(lg) {
		margin-bottom:1.3rem;
	}	
}
.footer__link {
	color:$grey-color;
	text-decoration:underline;
	@include hover-focus {
		color: $blue-color;
	}
}
.footer__center {
	margin:1rem 0;
	font-size: .9rem;
	@include media-breakpoint-up(lg) {
		margin-bottom:0;
		padding:0 2rem;
		margin:0;
		max-width:45%;
	}
	@include media-breakpoint-up(xl) {
		max-width:55%;
		padding:0 2rem;
	}
	@include media-breakpoint-up(xlg) {
		max-width:50%;
		
	}
}
.footer__menu {
	list-style:none;
	padding:0;
	margin:0 0 .5rem 0;
	color: $grey-color;
	font-weight:700;
	font-size:.9rem;
	text-transform:uppercase;
	li {
		display:inline-block;
		a {
			display:block;
			padding:.3rem .5rem; 
			color:$grey-color;
			@include hover-focus {
				color: $blue-color;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		margin:0 0 .9rem 0;
		li {
			margin:0 .7rem .5rem 0;
			& a {
				padding:0; 
			}
		}
	}
	@include media-breakpoint-up(xlg) {
		li {
		 	margin:0 .9rem 0 0;
		}
	}
}
.footer__am {
	font-size:.9rem;
	a {
		display:block;
		margin-bottom:.5rem;
		@include hover-focus {
			color: $blue-color;
		}
	}
	@include media-breakpoint-up(lg) {
		font-size:1rem;
		padding-left:2rem;
		background: url(../svg/mail.svg) 0 .4rem no-repeat;
		a {
			margin-bottom:0;
		}
	}
}
.footer__mail {
	@include media-breakpoint-up(lg) {
		padding-left:2rem;
	}
}

.footer__bottom {
	padding:0 0 1rem 0;
	@include media-breakpoint-up(lg) {
		padding:1.3rem 0;
		border-top: 1px solid $lightgrey-color-2;
		.container {
			display:flex;
		}
	}
}
.footer__phone {
	color:$text-color;
	font-size:1.05rem;
	line-height:1;
	margin-bottom:1rem;
	a {
		display:inline-block;
		color:$grey-color;
		@include hover-focus {
			color:$grey-color;
		}
		& + a  {
			border-left: 1px solid $lightgrey-color-3;
			padding-left:1rem;
			margin-left:1rem;
		}
	}
	@include media-breakpoint-up(lg) {
		font-size:1.1rem;
		line-height:.9;
	}	
}
.footer__soc {
	display: flex;
	justify-content: center;
	margin-top:1rem;
	a {
		@include flex-justify-self-center;
		background: rgba(33, 102, 143, 0.3);
		width:35px;
		height:35px;
		border-radius:50%;
		margin:0 .3rem;
		@include hover-focus { 
			background: rgba(33, 102, 143, 0.2);
		}
	}
	@include media-breakpoint-up(lg) {
		justify-content: flex-start;
		a {
			margin:0 .8rem 0 0;
		}
	}
}
.footer__legal-link {
	display: block;
	margin:.4rem 0 0 0;
	@include media-breakpoint-up(lg) {
		display:flex;
		margin:0 0 0 2.6rem;
	}
}
.footer__copy {
	margin:.4rem 0 0 0;
	a {
		color:$red-color;
		background:url(../svg/dotted-red.svg) bottom repeat-x;
		@include hover-focus { 
			color:$darkred-color;
			background-image: url(../svg/dotted-darkred.svg);
		}
	}
	@include media-breakpoint-up(lg) {
		margin:0 0 0 auto;
	}
}