@charset "utf-8";
@import "./variables";
@import "./mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.navbar-expand-lg {
	padding: 0;
	margin: 0;
	@include media-breakpoint-up(lg) {
		.navbar-toggler {
			display:block;
		}
	}
}
.navbar-collapse {
	position: fixed;
    z-index: 8010;
    top:0;
    left: 0;
	right:50px;
	background:$white-color;
	padding:0;
	box-shadow: 0 2px .2rem 0px rgba(0, 0, 0, .1);
	flex-basis: auto;
    flex-grow: inherit;
    align-items: center;
	transition: all ease-out 1s;
}
.navbar-nav__wrap {
	width: 100%;
    overflow: auto;
   	max-height: 100vh;
    overflow: scroll;
}
.navbar-toggler {
	border:none;
	padding:17px 18px;
	@include hover-focus {
		box-shadow:none;
	}
	.icon-bar {
		display: block;
		width:22px;
		height: 2px;
		border-radius: 0;
		background-color:$grey-color;
		transition: all ease-out .3s;
		&:nth-child(2) {
			margin: 4px 0;
		}
	}
}
.navbar-toggler-close {
	position: absolute;
	top:0;
	right: -50px;
	margin: 0;
	border: none;
	width: 50px;
	height: 100vh;
	.icon-bar__wrap {
		width: 50px;
		height: 50px;
		background-color:$red-color;
		position: absolute;
		top:0;
	}
	.icon-bar {
		display: block;
		width: 22px;
		height: 2px;
		border-radius: 2px;
		background-color:$white-color;
		position:absolute;
		top:16px;
		left:14px;
		&:first-child  {
			transform: translate3d(0, 7px, 0) rotate(45deg);
		}
		&:last-child  {
			transform: translate3d(0, -11px, 0) rotate(-45deg);
			top:34px;
		}
	}
}
.navbar-nav {
	list-style: none;
	padding: 0;
	margin: 0;
	> li {
		border-bottom:$lightgrey-color-2 solid 1px;
		> a {
			text-transform: uppercase;
			letter-spacing:0.03em;
			color: $grey-color;
			font-weight:700;
			line-height: 1.1;
			padding:1rem 2rem 1rem 1.3rem;
			color: $grey-color;
			display: block;
			background: url("../svg/menu-down.svg") 95% center no-repeat;
			@include hover-focus {
				color:$blue-color;
			}
			&.collapsed {
				background-image: url("../svg/menu-up.svg");
			}
		}
		&.active > a {
			color: $red-color;
			&:focus {
				text-decoration: none;
			}
		}
		> ul {
			list-style:none;
			font-size:1.1rem;
			padding:0;
			margin:-.5rem 0 .7rem 0;
			z-index:10;
			> li {
				> a {
					display:block;
					color: $grey-color;
					font-weight:400;
					@include square-marker;
					padding:.4rem 2.3rem .4rem 2.6rem;
					line-height: 1.1;
					&:before {
						left:1.3rem;
						top:.8rem;
					}
					@include hover-focus {
						color: $blue-color;
					}
				}	
				&.active > a {
					color: $red-color;
					&:before {
						background: $red-color;
					}
					&:focus {
						text-decoration: none;
					}
				}
				&:first-child {
					> a {
						font-weight:700;
					}
				}
				> ul {
					list-style: none;
					padding: 0;
					margin: 0 0 .3rem 0;
					z-index: 10;
					font-size: 1.1rem;
					& > li {
						& > a {
							padding: .4rem 2.3rem .4rem 2.6rem;
							position:relative;
							display:block;
							color:$grey-color;
							font-weight:400;
							line-height: 1.1;
							@include hover-focus {
								color:$blue-color;
							}
						}
						&.active > a {
							color: $red-color;
							&:focus {
								text-decoration: none;
							}
						}
						&:first-child {
							> a {
								font-weight:700;
							}
						}
					}
				}
			}
		}	
	}
}
.navbar-nav__wrap-footer {
	padding:1rem;
	margin-top:1rem;
	.header__phone {
		width:inherit;
		display:block;
		margin:0 0 .35rem 0;
		line-height:1.2;
		font-size:1.3rem;
		background-color:transparent;
		height:inherit
	}
	.header__work {
		color: #60697A;
		margin-bottom:2.35rem;
		font-size:.9rem;
	}
	.header__mail {
    	font-size: 1.1rem;
		margin:0 0 2.35rem 0;
		display:block;
	}
}
.header-top__mobile  {
	@include flex-between;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	height:50px;
	background:$white-color;
	border-bottom: 1px solid $lightgrey-color-2;
	@include media-breakpoint-up(lg) {
		display:none;
	}
}
.header-info {
	position:relative;
	display:none;
	> .container {
		@include flex-between;
	}
	@include media-breakpoint-up(lg) {
		display: block;
	}
}
.header-top {
	border-bottom: 1px solid $lightgrey-color-2;
	display:none;
	> .container {
		@include flex-between;
	}
	@include media-breakpoint-up(lg) {
		display: block;
	}
}
.logo {
	align-self: center;
}
.sticky-navigation {
	position: fixed;
	margin: 0 auto;
	z-index: 900;
	top: 0;
	left: 0;
	right: 0;
	background:$white-color;
	display:none;
	box-shadow: 0px .25rem 1rem rgba(0, 0, 0, 0.08);
	.sticky-navigation__inner {
		position:relative;
		> .container {
			@include flex-between;
		}
	}
	.header__catalog-button {
		 padding: .25rem 1rem;
	}
	.header__catalog {
    	padding: .625rem 0;
	}
	.nav-item {
    	margin-right: .5rem;
	}
	.header__icon-wrap {
		margin-left:.5rem;
		padding:.75rem 0 .85rem 0;
	}
	.header__icon {
		margin:0 auto;
	}
	.header__phone-wrap {
		font-size: 1rem;
	}
	&.d-block {
		display:none !important;
	}
	.header__phone {
		a {
			& + a  {
				padding-left:.3rem;
				margin-left:.3rem;
			}
		}
	}
	@include media-breakpoint-up(xl) {
		&.d-block {
			display: block !important;
		}
	}
	@include media-breakpoint-up(xlg) {
		.nav-item {
    		margin-right: 1rem;
		}
		.header__icon-wrap {
			margin-left:2rem;
		}
		.header__phone {
			a {
				& + a  {
					padding-left:.7rem;
					margin-left:.7rem;
				}
			}
		}
		.header__catalog-button {
			 padding: .25rem 1.5rem;
		}
	}
}
.nav-menu {
	display:block;
	padding:.5rem 0;
	.header-top__mobile & {
		background:$grey-color;
	}
	@include media-breakpoint-up(lg) {
		@include flex-wrap;
		align-items: center;
		line-height: 100%;
		padding:0;
		font-size:.9rem;
	}
	@include media-breakpoint-up(xlg) {
		font-size:1rem;
	}
}
.nav-item {
	position: relative;
	display:block;
	@include media-breakpoint-up(lg) {
		margin-right:.8rem;
		display:flex;
	}
	@include media-breakpoint-up(xl) {
		margin-right:1.5rem;
	}
	@include media-breakpoint-up(xxl) {
		margin-right:2rem;
	}
}
.nav-link {
	text-decoration: none;
	position: relative;
	margin:0;
	padding:0;
	color: $text-color;
	display: inline-block;
	line-height: 45px;
	@include hover-focus { 
		color:$blue-color;
		background: none;
	}
	.active & {
		color:$black-color;
		background: none;
	}
	.header-top__mobile & {
		display: block;
		font-size: 1.1rem;
		line-height: 1.3;
		color: $white-color;
		padding:.5rem 1.3rem;
		background:$grey-color;
		@include hover-focus { 
			color:$white-color;
			text-decoration: none;
			background-color:$text-color;
		}
		.active & {
			color:$red-color;
			text-decoration: none;
			background:$grey-color;
		}	
	}

}
.header__feedback {
	font-weight:700;
	color:$red-color;
	font-size:.82rem;
	line-height: 1.3;
	letter-spacing:0.02em;
	text-transform:uppercase;
	background: url(../svg/dotted-red.svg) bottom repeat-x;
	@include hover-focus {
		color:$darkred-color;
		background-image:url(../svg/dotted-darkred.svg);
	}
	.header-top__mobile &{
		display: block;
		color:$red-color;
		padding:.5rem 1.3rem;
		background:$grey-color;
	}
	@include media-breakpoint-up(lg) {
			font-size:.8rem;
	}
	@include media-breakpoint-up(xxl) {
			font-size:.82rem;
	}
}
.header__place {
	align-self: center;
	font-size:.95rem;
	background:url(../svg/place.svg) left center no-repeat;
	padding-left:1.1rem;
}

.header__user-menu {
	display:none;
	font-size: .95rem;
	position:absolute;
	top:100%;
	left:50%;
	width:9.4rem;
	margin-left:-4.7rem;
	z-index:1000;
	list-style:none;
	background:$white-color;
	box-shadow: 0 .25rem 1rem rgba(0, 0, 0, 0.08);
	border-radius:.2rem;
	padding:.5rem 0;
	a {
		color:$text-color;
		display:block;
		padding: .2rem 1.8rem;
		@include hover-focus {
			color: $blue-color;
		}
	}
	&:after {
    	@include after-before-abs;
    	left: 50%;
    	top: -10px;
    	margin-left: -10px;
    	width: 0;
   	 	height: 0;
    	border-color: transparent transparent $white-color;
    	border-style: solid;
    	border-width:0 10px 10px;
	}
}
.header__user {
	position:relative;
	&:hover  {
		.header__user-menu {
			display: block;
		}
	}
}
.header__catalog {
	padding:1.3rem 0;
	align-self: center;
}
.header__catalog-button {
	display: flex;
	align-items: center;
	color: $grey-color;
	font-weight:700;
	padding: .6rem 1.9rem;
	border-radius:.2rem;
	font-size:.9rem;
	line-height: 1.8;
	text-transform:uppercase;
	background:$yellow-color;
	&:before {
		@include after-before-inline;
    	width:19px;
    	height:12px;
		background:url(../svg/menu-icon.svg);
		margin:0 10px 0 0;
		vertical-align:middle;
	}
	@include hover-focus { 
		color:$text-color;
		background:$lightyellow-color;
	}
	@include media-breakpoint-up(xxl) {
			padding: .6rem 2.5rem;
	}
}
.header__catalog-menu {
	position:absolute;
	display:none;
	top:100%;
	left:0;
	right:0;
	z-index:100;
	.header__catalog:hover &{
		display:block;
		transition: all ease-out .4s;
	}
	&:before {
		@include after-before-abs;
		background:$white-color;
		height: 7px;
		top: -7px;
		left: 0;
		right: 0;
		z-index: 100;
	}
}
.header__catalog-inner {
	background:$white-color;
	padding:0;
	box-shadow: 0 .25rem 1rem rgba(0, 0, 0, 0.08);
	border-radius:0 0 .4rem .4rem;
	max-height:calc(100vh - 160px);
    overflow-y: auto;
	position:relative;
	
}
.header__catalog-list {
	@include flex-wrap;
	list-style:none;
	padding:2rem 0;
	margin:0;
	width:100%;
	line-height:1.4;
	> li {
		width:20%;
		display: block;
		padding:0 2rem;
		background:url(../svg/stripe_v.svg) right repeat-y;
		&:nth-child(5n) {
			background:none;
		}
		&:nth-child(n+6) {
			padding-top:2rem;
		}
		> a {
			color:$grey-color;
			font-weight:700;
			text-transform:uppercase;
			margin-bottom:.8rem;
			display:block;
			@include hover-focus {
				color:$blue-color;
			}
			
		}
		> ul {
			list-style:none;
			padding:0;
			margin:0;
			line-height:1.4;
			> li {
				display:block;
				font-weight:400;
				margin-bottom:.6rem;
				@include square-marker;
				> a {
					color:$text-color;
					@include hover-focus {
						color:$blue-color;
					}
					&.catalog-list__all {
						color:$blue-color;
						@include hover-focus {
							color:$darkblue-color;
						}
					}
				}
			}
		}	
	}
}
.header__catalog-img {
	width:106px;
	margin:0 auto 1.3rem auto;
	padding:3px;
	border-radius:50%;
	border:$lightgrey-color-2 solid 1px;
	img {
		border-radius:50%;
	}
}
.header__catalog-title {
	margin-bottom:1rem;
}
.header__search {
	display: flex;
	align-self: center;
	.form-control {
		background:$lightblue-color;
		border-color:$lightblue-color;
		padding-right:4rem;
		&:focus {
			border-color:$lightblue-color;
			background:$lightblue-color-2;
		}
	}
	form {
		position:relative;
		width:100%;
	}
	.header__search-wrap & {
		display: none;
		position: absolute;
		top: 49px;
		left: 0;
		right: 0;
		margin: 0;
		float: none;
		width: 100%;
		border-top: 1px solid $lightgrey-color-2;
		box-shadow: 0px .2rem .2rem rgba(0, 0, 0, .1);
		background:$white-color;
		& form {
			padding:1rem;
		}
	}
	.header__search-wrap.active & {
		display:block
	}
	@include media-breakpoint-up(lg) {
		width:40%;
		form {
			padding:0;
		}
	}
	@include media-breakpoint-up(xl) {
		width:42%;
	}
	@include media-breakpoint-up(xxl) {
		width:42%;
	}
	@include media-breakpoint-up(xlg) {
		width:50%;
	}
}
.header__search-button {
	position:absolute;
	top:0;
	border:none;
	right:0;
	bottom:0;
	z-index:10;
	background-color:transparent;
	background:url(../svg/search.svg) center no-repeat;
	width:42px;
	border-radius:.2rem .2rem 0 0
}

.header__phone {
	align-self: center;
	line-height:.9;
	position:relative;
	a {
		display:block;
		padding:.5rem 1.3rem;
		color:$text-color;
	}
	@include media-breakpoint-up(xs360) {
		a {
			display:inline-block;
			padding:0;
			& + a  {
				border-left: 1px solid $lightgrey-color-2;
				padding-left:.7rem;
				margin-left:.7rem;
			}	
		}
	}
	@include media-breakpoint-up(xl) {
		a {
			& + a  {
				padding-left:1rem;
				margin-left:1rem;
			}	
		}
	}
}
.header__phone-wrap {
	font-size:1.1rem;
	padding:.6rem 0;
	@include media-breakpoint-up(xs360) {
		font-size:1.05rem;
		padding:1.3rem;
	}
	@include media-breakpoint-up(md)  {
		font-size:1rem;
	}
	@include media-breakpoint-up(lg) {
		padding:0;
	}
	@include media-breakpoint-up(xl) {
		font-size:1.1rem;
	}
}
.header__working {
	padding:0 1.3rem 1.3rem 1.3rem; 
	border-bottom:$lightgrey-color-2 solid 1px;
	color:$grey-color;
	font-size:.9rem;
	@include media-breakpoint-up(lg) {
		background:$white-color;
		box-shadow: 0px .25rem 1rem rgba(0, 0, 0, 0.08);
		padding:.7rem .8rem;
		z-index:10;
		border-radius:.2rem;
		margin-top:.7px;
		font-size:.8rem;
		line-height:1;
		position:absolute;
		display:none;
		top:1.4rem;
		left:50%;
		width:11rem;
		margin-left:-5.5rem;
		text-align:center;
		&:after {
    		@include after-before-abs;
    		left: 50%;
    		top: -.65rem;
    		margin-left: -.65rem;
    		width: 0;
   	 		height: 0;
    		border-color: transparent transparent $white-color;
    		border-style: solid;
    		border-width:0 .65rem .65rem;
		}
		.header__phone:hover & {
			display:block;
		}
	}
}
.header__icon-menu {
	padding:.5rem 0;
}
.header__icon-wrap {
	color:$grey-color;
	font-size:.85rem;
	line-height:1;
	text-align:center;
	display: block;
	padding:1.3rem 0;
	margin-left:1.5rem;
	@include hover-focus {
		color: $blue-color;
	}
	.header__user & {
		margin-left:0;
	}
	.header-top__mobile &  {
		padding:.65rem .3rem;
		margin:0;
		&:last-child {
			padding-right:1rem;
		}
	}
	.header__icon-menu &  {
		@include flex-wrap;
		padding:.5rem 1.3rem;
		position:relative;
		margin-left:0;
		font-size:1.1rem;
		font-weight:400;
	}
	@include media-breakpoint-up(xxl) {
		margin-left:3rem;
	}
}
.header__icon-top {
	@include flex-between;
	align-self: center;
	flex-wrap: wrap;
}
.header__icon {
	width:30px;
	height:2.35rem;
	margin:0 auto 5px auto;
	position:relative;
	@include flex-justify-self-center;
	align-items: center;
		img {
			display:block;
		}
	.header__icon-top &  {
		margin:0 auto;
	}
	.header__icon-menu &  {
		margin:0;
		position: static;
	}
}
.header__icon-title {
	.header__icon-menu &  {
		display: flex;
		align-self: center;
		padding-left:1rem
	}
}
.header__count {
	position:absolute;
	right:-7px;
	top:-5px;
	min-width:18px;
	line-height:18px;
	border-radius:50%;
	text-align:center;
	font-size:.7rem;
	padding:0 .2rem;
	color:$grey-color;
	font-weight:700;
	background:$yellow-color;
	.header__icon-menu &  {
		right:1.2rem;
		min-width:22px;
		line-height:22px;
		font-size:.8rem;
		top:50%;
		margin-top:-11px;
	}
	@include media-breakpoint-up(lg) {
		right:-9px;
		top:-7px;
		min-width:21px;
		line-height:21px;
		font-size:.8rem;
	}
}
.header__logged {
	position:absolute;
	right:-9px;
	top:-7px;
	width:21px;
	height:21px;
	border-radius:50%;
	text-align:center;
	background:$blue-color url(../svg/check_w.svg) center center no-repeat ;
	.header__icon-menu &  {
		right:1.2rem;
		width:22px;
		height:22px;
		top:50%;
		margin-top:-11px;
	}
}