@charset "utf-8";

$grid-breakpoints: (
	xs: 0,
	xs360: 360px,
	xs410: 410px,
	xs500: 500px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1320px,
	xlg: 1400px
	
);
$black-color: #000;
$white-color: #fff;
$text-color: #262626;

$grey-color: #4a4a4d;
$darkgrey-color: #3a3a3a;
$lightgrey-color: #f8f8f8;
$lightgrey-color-2: #eaeaea;
$lightgrey-color-3: #b8b8b8;
$lightgrey-color-4: #f3f3f3;
$lightgrey-color-5: #cacaca;

$blue-color: #459fd0;
$darkblue-color: #166087;
$lightblue-color: #f4f7f9;
$lightblue-color-2: #f8fafc;
$lightblue-color-3: #73bfe8;

$red-color: #ff744b;
$darkred-color: #a63818;
$lightred-color: #ff9678;

$yellow-color: #ffce4b;
$lightyellow-color: #ffda78;
