@charset "utf-8";
@import "./variables";
@import "./mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.sorting {
    margin: 0 -1rem;
    padding: 1rem 1rem 0 1rem;
    background: $lightgrey-color;
    color: $text-color;
    line-height: 1.2;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-up(md) {
        display: flex;
        margin: -.5rem 0 1.3rem 0;
        padding: 0;
        background: $white-color;
    }
}

.sorting__type {
    line-height: 1.2;
    padding: .5rem 0;
    display: none;
    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        align-self: center;
        padding: 0;
    }
}

.sorting__button {
    margin-left: 1rem;
    color: $blue-color;
    background: url("./../svg/dotted.svg") bottom repeat-x;
    padding: 0;
    @include hover-focus {
        color: $darkblue-color;
        background-image: url("./../svg/dotted-darkblue.svg");
    }

    &.active {
        color: $text-color;
        padding-right: 1rem;
        background: none;
        @include hover-focus {
            color: $text-color;
        }
    }

    &.up {
        background-position: right center;
        background-repeat: no-repeat;
        background-image: url("./../svg/up_blue.svg");
    }

    &.down {
        background-position: right center;
        background-repeat: no-repeat;
        background-image: url("./../svg/down_blue.svg");
    }
}

.sorting__view {
    @include flex-between;
    align-self: center;

    a {
        @include flex-justify-items-center;
        position: relative;
        color: $white-color;
        padding: .4rem .2rem;
        line-height: 1.4;
        background-color: $blue-color;

        &:before {
            @include after-before-inline;
            width: 16px;
            height: 16px;
            margin: 0 10px 0 0;
            vertical-align: middle;
        }

        &:last-child {
            background-color: $red-color;
        }
        @include hover-focus {
            background-color: $lightblue-color-3;
            color: $white-color;

            &:last-child {
                background-color: $lightred-color;
            }
        }
    }
    @include media-breakpoint-up(md) {

        a {
            padding: .4rem .6rem;
        }
    }
    @include media-breakpoint-up(lg) {

        a {
            margin: 0;
            padding: 0;
            text-indent: -9000%;
            width: 2.2rem;
            height: 2.2rem;
            background-position: center center;
            background-repeat: no-repeat;
            background-color: $lightgrey-color-2;
            border: $lightgrey-color-2 solid 1px;
            @include hover-focus {
                background-color: $lightgrey-color-2;

                &:last-child {
                    background-color: $lightgrey-color-2;
                }
            }

            &.active {
                background-color: $white-color;
                border-color: $lightgrey-color-2;
            }

            &:nth-child(2n) {
                margin: 0;
            }

            &:before {
                display: none;
            }

            &:last-child {
                display: none;
            }
        }
    }
}

.sorting__table {
    width: calc(50% - .5rem);
    margin: 0 .5rem 0 0;
    border-radius: .2rem;

    &.active {
        display: none;
    }

    &:before {
        background-image: url("./../svg/table_icon.svg");
    }
    @include media-breakpoint-up(lg) {
        background-image: url("./../svg/sorting-table.svg");
        border-radius: .2rem 0 0 .2rem;
        @include hover-focus {
            background-image: url("./../svg/sorting-table_h.svg");
        }

        &.active {
            background-image: url("./../svg/sorting-table_h.svg");
            margin-right: -1px;
            display: block;
        }
    }
}

.sorting__list {
    width: calc(50% - .5rem);
    margin: 0 .5rem 0 0;
    border-radius: .2rem;

    &.active {
        display: none;
    }

    &:before {
        background-image: url("./../svg/list_icon.svg");
    }
    @include media-breakpoint-up(lg) {
        background-image: url("./../svg/sorting-list.svg");
        border-radius: 0 .2rem .2rem 0;
        @include hover-focus {
            background-image: url("./../svg/sorting-list_h.svg");
        }

        &.active {
            background-image: url("./../svg/sorting-list_h.svg");
            margin-left: -1px;
            display: block;
        }
    }
}

.sorting__filter {
    width: calc(50% - .5rem);
    margin: 0 0 0 .5rem;

    &:before {
        background-image: url("./../svg/filter-icon.svg");
    }
}

.filters__fix {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    max-height: 100vh;
    min-height: 100vh;
    padding-top: 50px;
    -webkit-overflow-scrolling: touch;
    display: none;
    @include media-breakpoint-up(lg) {
        z-index: 0;
        position: relative;
        padding: 0;
        display: block;
        max-height: inherit;
        min-height: inherit;
    }
}

.filter__header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: $white-color;
    font-size: 1.2rem;
    font-weight: 700;
    color: $grey-color;
    border-bottom: 1px solid $lightgrey-color-2;
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.filter__header-title {
    position: relative;

    .navbar-toggler-close {
        right: 0;
    }
}

.filter__body {
    overflow: scroll;
    max-width: 540px;
    max-height: calc(100vh - 160px);
    min-height: calc(100vh - 160px);

    @include media-breakpoint-up(lg) {
        overflow: inherit;
        max-height: inherit;
        min-height: inherit;
        max-width: inherit;
    }
}

.filter__wrap {
    background: $white-color;
    @include media-breakpoint-up(lg) {
        overflow: inherit;
        background: $lightgrey-color;
        border-radius: .4rem;
    }
}

.filter__item {
    border-bottom: 1px solid $lightgrey-color-2;

    &:first-child {
        padding: .5rem 1rem;
    }

    &:last-child {
        border: none;
    }
    @include media-breakpoint-up(lg) {
        border-color: $lightgrey-color-5;
    }
    @include media-breakpoint-up(xl) {

        &:first-child {
            padding: 1rem 1.5rem;
        }
    }
}

.filter__title {
    font-size: .9rem;
    line-height: 1.3;
    font-weight: 700;
    color: $grey-color;

    &-link {
        display: block;
        padding: 1rem 2rem 1rem 1rem;
        color: $grey-color;
        position: relative;

        &:after {
            @include after-before-abs;
            top: 1.3rem;
            right: 1rem;
            width: 10px;
            height: 10px;
            background: url("./../svg/menu-down.svg");
        }
        @include hover-focus {
            color: $blue-color;
        }

        &.collapsed {

            &:after {
                transform: rotate(-180deg);
                transition: transform 150ms ease-in-out 0s;
            }
        }
    }

    & span {
        display: block;
        padding: 1rem 2rem 1rem 1rem;
    }
    @include media-breakpoint-up(xl) {

        a, span {
            padding: 1.5rem 2.5rem 1.5rem 1.5rem;

            &:after {
                top: 1.8rem;
                right: 1.5rem;
            }
        }
    }
}

.filter__inner {
    margin: -.3rem 1rem 1rem 1rem;
    z-index: 10;
    @include media-breakpoint-up(xl) {
        margin: -.5rem 1.5rem 1rem 1.5rem;
    }
}

.filter__button {
    padding: 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background: $white-color;
    border-top: $lightgrey-color-2 solid 1px;

    & .btn {
        display: block;
        max-width: 100%;
        width: 100%;
        padding: .8rem 1rem;

        & + .btn {
            margin: .7rem 0 0 0;
        }
    }
    @include media-breakpoint-up(lg) {
        padding: 1rem;
        background: $lightgrey-color;
        border-color: $lightgrey-color-5;
        position: relative;

        & .btn {
            padding: 1rem;
            margin: 0 auto;

            & + .btn {
                margin: 1rem auto 0 auto;
            }
        }
    }
    @include media-breakpoint-up(xl) {
        padding: 1rem 1.5rem;
    }
}

.filter__input-wrap {
    position: relative;

    &:after {
        @include after-before-abs;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 1px;
        margin-left: -5px;
        background: $lightgrey-color-5;
    }
}

.filter__input {
    position: relative;
    width: calc(50% - .5rem);
    margin: 0 .5rem 0 0;

    &:last-child {
        margin: 0 0 0 .5rem;
    }

    & label {
        position: absolute;
        left: 0.8rem;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        font-size: .9rem;
        color: $lightgrey-color-5;
    }

    .filter__range & {

        & input {
            padding: 0.6rem 0.7rem 0.6rem 2rem;
        }
    }
}

.filter__slider {
    margin: 1rem 1rem 1.5rem 1rem;
    z-index: 10;
    @include media-breakpoint-up(lg) {
        margin: 1rem 1.5rem 1.5rem 1.5rem;
    }
}
