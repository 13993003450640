@charset "utf-8";
@import "./variables";
@import "./mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.contact__top {
    margin-bottom: 1.2rem;
    @include media-breakpoint-up(lg) {
        @include flex-wrap;
        margin-bottom: 2rem;
    }
    @include media-breakpoint-up(xl) {
        margin-bottom: 4rem;
    }
}

.contact__block {
    line-height: 1.4;
    margin-bottom: 1.2rem;
    display: flex;
    @include media-breakpoint-up(lg) {
        flex-direction: column;
        margin: 0;
        width: 20%;
        padding: 0 1rem;
        background: url("../svg/stripe_v.svg") right repeat-y;

        &:nth-child(2n) {
            margin: 0;
        }

        &:first-child {
            padding: 0 1rem 0 0;
        }

        &:last-child {
            background: none;
            padding: 0 0 0 1rem;
        }
    }
    @include media-breakpoint-up(xl) {
        padding: 0 2rem;

        &:first-child {
            padding: 0 2rem 0 0;
        }

        &:last-child {
            padding: 0 0 0 2rem;
        }
    }
}

.contact__icon {
    width: 3.5rem;
    height: 3.5rem;
    min-width: 3.5rem;
    background: $red-color;
    border-radius: 50%;
    margin: 0 1rem 0 0;
    @include flex-justify-items-center;
    @include media-breakpoint-up(lg) {
        width: 3.7rem;
        height: 3.7rem;
        min-width: 3.7rem;
        margin: 0 0 1.3rem 0;
    }
}

.contact__title {
    margin-bottom: .3rem;
    padding: .2rem 0 0 0;
    @include media-breakpoint-up(lg) {
        padding: 0;
    }
}

.contact__note {
    color: $grey-color;
    font-weight: 700;
    font-size: 1.1rem;

    a {
        display: block;
        color: $grey-color;
    }
}

.contact__bottom {
    @include media-breakpoint-up(lg) {
        @include flex-justify-start;
    }
}

.contact__map {
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
        width: 60%;
        margin: 0;
    }
}

.contact__data {
    padding: 0;
    @include media-breakpoint-up(lg) {
        width: 30%;
        padding: 0 0 0 2rem;
    }
    @include media-breakpoint-up(xl) {
        padding: 0 0 0 6rem;
    }
}
