@charset "utf-8";
@import "~tippy.js/dist/tippy.css";

.tippy-box[data-theme~="default"] {
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
	font-size: 15px;

	.tippy-content {
		font-size: .75rem;
		line-height: 1.3;
	}

	.tippy-arrow {
		color: rgba(0, 0, 0, 0.5);
	}

	&[data-placement^=bottom] {

		>.tippy-arrow {

			&:before {
				top: -8px;
			}
		}
	}

	&[data-placement^=top] {

		>.tippy-arrow {

			&:before {
				bottom: -8px;
			}
		}
	}

	&[data-placement^=left] {

		>.tippy-arrow {

			&:before {
				right: -8px;
			}
		}
	}

	&[data-placement^=right] {

		>.tippy-arrow {

			&:before {
				left: -8px;
			}
		}
	}
}
