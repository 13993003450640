$enable-smooth-scroll: false;
@charset "utf-8";
@import "./contact";
@import "./profile";
@import "./basket";
@import "./filter";
@import "./catalog";
@import "./blog";
@import "./slider";
@import "./range-slider";
@import "./tooltip";
@import "./footer";
@import "./header";
@import "./variables";
@import "./mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@font-face {
    font-family:PT Sans;
    font-style:normal;
    font-weight:400;
    font-display:swap;
    src: local("PT-Sans"), url("./../fonts/PT-Sans.woff2") format("woff2"), url("./../fonts/PT-Sans.woff") format("woff");
}
@font-face {
    font-family:PT Sans;
    font-style:italic;
    font-weight:400;
    font-display:swap;
    src: local("PT-SansItalic"), url("./../fonts/PT-SansItalic.woff2") format("woff2"), url("./../fonts/PT-SansItalic.woff") format("woff");
}

@font-face {
    font-family:PT Sans;
    font-style:normal;
    font-weight:700;
    font-display:swap;
    src: local("PT-SansBold"), url("./../fonts/PT-SansBold.woff2") format("woff2"), url("./../fonts/PT-SansBold.woff") format("woff");
}



html {
    height: 100%;
    font-size: 13px;
    @include media-breakpoint-up(lg) {
        font-size: 15px;
    }
}

body {
    height: 100%;
    position: relative;
    margin: 0;
    padding: 50px 0 0 0;
    font-family:PT Sans, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    line-height: 1.6;
    color: $text-color;
    background: $white-color;
    @include media-breakpoint-up(lg) {
        padding: 0;
    }
}

h2, h3, h4, h5 {
    color: $grey-color;
    font-weight:700;
}

h1 {
    color: $grey-color;
    font-weight:700;
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: .8rem;
    .catalog__title & {
        margin: 0;
        opacity: 0;
        height: 0;
    }
    @include media-breakpoint-up(lg) {
        font-size: 2rem;
        line-height: 1.3;
        margin: 0 0 1.3rem 0;
        .catalog__title & {
            opacity: 1;
            margin: 0 0 1.3rem 0;
            height: inherit;
            padding: 0;
            border: none;
        }

        .main-about & {
            position: relative;
            min-width: 35%;
            padding-right: 2rem;
            font-size: 2rem;
            line-height: 1.2;

            &:after {
                @include after-before-abs;
                top: 7.5rem;
                left: 0;
                width: 3.5rem;
                height: .2rem;
                background: $red-color;
            }
        }
    }
    @include media-breakpoint-up(xxl) {

        .main-about & {
            min-width: 27%;
        }

        .content-inner & {
            border-bottom: 1px solid $lightgrey-color-2;
            margin: 0 0 1.5rem 0;
            padding-bottom: 1.2rem;
        }

        .catalog__title & {
            padding: 0;
            border: none;
        }
    }
}

.title-h1 {
    color: $grey-color;
    font-weight:700;
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.title-wrap {
    @include flex-between;
    margin-bottom: .5rem;

    a {
        text-decoration: underline;
    }
}

h2 {
    font-size: 1.3rem;
    margin: 0 0 .7rem 0;

    & + h3 {
        margin-top: .4rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 1.6rem;
        margin: 0 0 1.5rem 0;

        & + h3 {
            margin-top: .4rem;
        }
    }
}

h3 {
    font-size: 1.2rem;
    margin: 0 0 .7rem 0;

    & + h4 {
        margin-top: .4rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 1.6rem;
        margin: 0 0 1.8rem 0;

        & + h4 {
            margin-top: .4rem;
        }
    }
}

h4 {
    font-size: 1.1rem;
    margin: 0 0 .7rem 0;

    & + h5 {
        margin-top: .4rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
        margin: 0 0 1.5rem 0;

        & + h5 {
            margin-top: .4rem;
        }
    }
}

h5 {
    font-size: 1.1rem;
    margin: 0 0 1rem 0;

    & + h6 {
        margin-top: .4rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 1.1rem;
        margin: 0 0 1.4rem 0;

        & + h6 {
            margin-top: .4rem;
        }
    }	
}

h6 {
    font-size: 1rem;
    margin: 0 0 .7rem 0;	
}

p {
    margin: 0 0 .8rem 0;

    & + h2, & + h3, & + h4 {
        margin-top: 1.5rem;
    }
}

ul {
    .content-body &, &.page-list, .tab-pane & {
        padding-left: 0;
        list-style: none;
        & + p, & + h2, & + h3, & + h4, & + blockquote {
            margin-top: 1.5rem;
        }

        li {
            margin-bottom: .4rem;
            @include square-marker;
            &:before {
                top: .6rem;
            }
        }
    }
}

ol {

    article & {
        list-style: none;

        & + p, & + h2, & + h3, & + h4, & + blockquote {
            margin-top: 1.1rem;
        }

        &  li {
            margin-bottom: .4rem;
        }
    }
}

a {
    color: $blue-color;
    text-decoration: none;
    @include hover-focus {
        outline: none;
        color: $darkblue-color;
    }
    transition-property: background-color, color;
    transition-duration: .2s;
    transition-timing-function: ease-out;
}

.ul-page {
    list-style: none;
    & + p, & + h2, & + h3, & + h4, & + blockquote {
        margin-top: 1.1rem;
    }
    li {
        margin-top: .4rem;
    }
}

b, strong {
    font-weight:700;
    color: $grey-color;
}

i, em {
    font-style:italic;	
}

i b, em b, i strong, em strong, b i, em b, strong i , strong em {
    font-style:italic;
}

fieldset {
    padding: .35rem .625rem .75rem;
}

iframe {
    border: none;
}

input[type="submit"]:focus, button:focus {
    border: none;
    outline: none;
}

::-ms-clear {
    display: none;
}

::-ms-reveal {
    display: none;
}

label {
    font-weight: normal;
}

button {
    border: none;
    padding: 0;
    background-color: transparent;
    &:focus {
        transition: all ease-out .2s;
    }
}

img {
    max-width: 100%;
}

.table {
    line-height: 1.2;
    font-size: .9rem;
    border: $lightgrey-color-5 solid 1px;
    margin-bottom: 1.5rem;
    tr {
        border-top: none;
        border-bottom: none;

        &:nth-of-type(odd) {
            background-color: $lightgrey-color;
        }
    }

    td, th {
        padding: .5rem;
        border-top: none;
        border-bottom: none;
    }

    th {
        color: $white-color;
        font-weight: normal;
        color: $grey-color;
        font-weight:700;
    }
    @include media-breakpoint-up(lg) {
        font-size: 1rem;
        td, th {
            padding: .9rem 1rem;
        }
    }	
}

blockquote {
    background: $lightgrey-color;
    margin: 0 -1rem 1rem -1rem;
    padding: 1rem;
    & p {
        margin: 0;
    }

    & + h2, & + h3, & + h4 {
        margin-top: 1.5rem;
    }
    @include media-breakpoint-up(sm) {
        margin: 0 0 .7rem 0;
    }
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-control {
    border: 1px solid $lightgrey-color-2;
    padding: .6rem 1.1rem;
    outline: none;
    border-radius: .2rem;
    box-shadow: none;
    background-color: $white-color;
    height: inherit;
    &:focus {
        transition: all ease-out .2s;
        border-color: $lightgrey-color-3;
        background-color: $white-color;
        outline: hidden;
        box-shadow: none;
    } 
}

.form-control {

    ::placeholder {
        color: $grey-color;
    }
}

.form-select {
    border: 1px solid $lightgrey-color-2;
    padding: .6rem 1.1rem;
    outline: none;
    border-radius: .2rem;
    box-shadow: none;
    background-color: $white-color;
    height: inherit;
    &:focus {
        transition: all ease-out .2s;
        border-color: $lightgrey-color-3;
        background-color: $white-color;
        outline: hidden;
        box-shadow: none;
    } 
}

.row +.row {
    margin-top: 1.3rem;
    @include media-breakpoint-up(lg) {
        margin-top: 1rem;
    }
}

.col-12 {

    & + & {
        margin-top: 1.3rem;
    }
    @include media-breakpoint-up(md) {

        .feedback__wrap & + & {
            margin-top: 0;
        }
    }
    @include media-breakpoint-up(lg) {

        & + & {
            margin-top: 0;
        }
    }
}

input[type="file"] {
    background: transparent;
    border: none;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
}

.input__wrapper {
    width: 100%;
    position: relative;
    border: 1px solid $lightgrey-color-2;
    outline: none;
    border-radius: .2rem;
    box-shadow: none;
    background-color: $white-color;
    cursor: pointer;
    overflow: hidden;
	height:2.8rem;
    &:before {
        @include after-before-abs;
        background: $blue-color  url(../svg/file.svg) center no-repeat;
        width: 3rem;
        right: -1px;
        top: -1px;
        bottom: -1px;
        border-radius: 0 .2rem .2rem 0;
    }

    label {
        cursor: pointer;
        display: block;
		position:absolute;
		height:2.8rem;
        padding: .6rem 0 .6rem 1.1rem;
		width:100%;
    }

    .form__col + & {
        margin-top: 1rem;
    }
    @include media-breakpoint-up(lg) {

        .form__col + & {
            margin-top: 1.5rem;
        }
    }
}

.form-text {
    font-size: .85rem;
    letter-spacing: .03em;
    margin: .3rem 0 0 0;
    color: $text-color;
    line-height: 1.3;
    @include media-breakpoint-up(lg) {
        font-size: .8rem;
    }
}

.text-grey {
    font-size: .9rem;
    opacity: 0.7;
}

.text-danger {
    color: $red-color !important;
}

.text-success {
    color: $blue-color !important;
}

textarea.form-control, textarea.form-control:focus {
    height: inherit;
}

.form-horizontal .control-label {
    text-align: right;
    font-size: .9rem;
}

input[type="file"] {
    background: transparent;
    border: none;
}

input[type="submit"]:focus {
    border: none;
    outline: hidden;
}

.btn {
    font-size: .85rem;
    padding: 1em 1.5em;
    color: $white-color;
    text-transform: uppercase;
    border-radius: .2rem;
    display: block;
    cursor: pointer;
    margin: 0 auto;
    min-width: 12rem;
    border: none;
    letter-spacing: .03em;
    font-weight:700;
    position: relative;
    white-space: nowrap;
    background-color: $blue-color;
    &:hover, &:focus, &.active.focus, &.active:focus,  &active.focus, &active:focus,  &:active:focus {
        transition: all ease-out .2s;
        box-shadow: none;
        color: $white-color;
        background-color: $lightblue-color-3;
        text-decoration: none;
        outline: hidden;
        outline-offset: none;
        outline: none;
        outline-offset: 0;
    }

    & + & {
        margin: .9rem auto 0 auto;
    }

    .main & {
        position: relative;
        overflow: hidden;
    }
    @include media-breakpoint-up(sm) {
        margin: 0;
        display: inline-block;
        max-width: inherit;
    }
    @include media-breakpoint-up(md) {
        padding: 1.3em 3em;

        & + & {
            margin: 0 0 0 1.5rem;
        }
    }
}

.btn-primary {
    background-color: $yellow-color;
    color: $grey-color;

    &:hover, &:focus, &.active.focus, &.active:focus,  &active.focus, &active:focus,  &:active:focus {
        background-color: $lightyellow-color;
        color: $text-color;
    }
}

.btn-secondary {
    background-color: $lightgrey-color-5;
    color: $white-color;

    &:hover, &:focus, &.active.focus, &.active:focus,  &active.focus, &active:focus,  &:active:focus {
        background-color: $lightgrey-color-3;
        color: $white-color;
    }
}

.btn-delete {
    background-color: $red-color;
    color: $white-color;

    &:hover, &:focus, &.active.focus, &.active:focus,  &active.focus, &active:focus,  &:active:focus {
        background-color: $lightred-color;
        color: $white-color;
    }
}

.error {
    color: #f00;
}

.modal {
    overflow-y: auto;
}

.modal-open {
    overflow: auto;
}

input[type=radio], input[type=checkbox] {
    margin: 0;
}

.red-color {
    color: $red-color;
}

.checkbox, .radio {
    margin: 0;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    cursor: pointer;

    input[type=radio], & input[type=checkbox] {
        top: 0;
        position: absolute;
        opacity: 0;
        clip: rect(0, 0, 0, 0);

        &:checked + label:after, &:checked +  label:hover:after {
            border-color: $blue-color;
        }
    }

    & label {
        padding: .5rem 0 .5rem 35px;
        position: relative;
        display: block;
        cursor: pointer;

        &:after {
            @include after-before-abs;
            width: 20px;
            height: 20px;
            background-color: $white-color;
            border: 1px solid $lightgrey-color-5;
            box-sizing: border-box;
            left: 0;
            top: .5rem;
            margin-right: 5px;
        }
    }
    @include hover-focus {

        label {

            &:after {
                border-color: $blue-color;
            }
        }
    }

    &.disabled {
        opacity: 0.4;
        cursor: default;

        label {
            cursor: default;
        }
        @include hover-focus {

            label {

                &:after {
                    border-color: $lightgrey-color-5;
                }
            }
        }
    }
}

.checkbox {

    input[type=checkbox] {

        &:checked + label:after, &:checked +  label:hover:after {
            background-color: $blue-color;
            background: $blue-color url(../svg/checkbox.svg) center no-repeat;
        }
    }

    label {

        &:after {
            border-radius: .2rem;
        }
    }
}

.radio {

    input[type=radio] {

        &:checked + label:after, &:checked +  label:hover:after {
            background-color: $white-color;
            border-width: 6px;	
        }
    }

    label {

        &:after {
            border-radius: 50%;
        }
    }
    @include hover-focus {

        label {

            &:after {
                border-color: $blue-color;
            }
        }
    }
}

.plus, .minus {
    font-size: 1.5rem;
}

.container {
    padding-left: 1rem;
    padding-right: 1rem;
    @include media-breakpoint-up(xlg) {
        max-width: 1430px;
    }
}

.breadcrumb__wrap {
    padding: 1rem 0 .7rem 0;
    @include media-breakpoint-up(lg) {
        background: $lightgrey-color;
        margin-bottom: 1.4rem;
        padding: 1.2rem 0 .8rem 0;
    }
}

.breadcrumb {
    background-color: transparent;
    padding: 0;
    margin: 0;
    border-radius: 0;
    font-size: .9rem;
    line-height: 1;
}

.breadcrumb-item {
    margin-bottom: .3rem;

    a {
        color: $blue-color;
        @include hover-focus {
            color: $darkblue-color;
        }
    }

    &.active a {
        color: $grey-color;
    }

    & + &:before {
        content: ">";
        float: none;
    }
    @include media-breakpoint-up(lg) {

        a, &.active a {
            color: $grey-color;
            @include hover-focus {
                color: $blue-color;
            }
        }
    }
}

.pagination__container {
    margin: 1rem 0 0 0;
    color: $grey-color;
    font-weight:700;
    font-size: .9rem;

    .btn {
        margin-left: auto;
        margin-right: auto;
        max-width: 16rem;
        display: block;

        span {
            pointer-events: none;
        }
    }
    @include media-breakpoint-up(lg) {
        margin: 2rem 0 0 0;

        .btn {
            margin: 0;
            display: inline-block;
            width: inherit;
        }
    }
}

.pagination {
    padding: 0;
    margin: 0;
    border-radius: .2rem;

    .btn + & {
        margin: 1rem 0 0 0;
    }

    & + .btn {
        margin-top: 1rem;
    }
    @include media-breakpoint-up(lg) {
        .btn + & {
            margin: 0 0 0 3rem;
        }

        & + .btn {
            margin: 0 0 0 3rem;
        }
    }
}

.page-link {
    padding: .9rem 1.3rem;
    border: $lightgrey-color-5 solid 1px;
    margin: 0 0 0 -1px;
    text-align: center;
    color: $text-color;
    background-color: $white-color;
    @include hover-focus {
        color: $white-color;
        background-color: $grey-color;
        box-shadow: none;
        border-color: $grey-color;
    }
}

.page-item {
    margin: 0;

    &:first-child {

        .page-link {
            border-radius: .2rem 0 0 .2rem;
        }
    }

    &:last-child {

        .page-link {
            border-radius: 0 .2rem .2rem  0;
        }
    }

    &.active .page-link {
        color: $white-color;
        background-color: $blue-color;
        border-color: $blue-color;
    }
}

.form-container {
    margin: 0;
}

label.error {
    margin: 0;
    font-size: .7rem;
    line-height: 1;
}

.video-iframe {
    margin-left: -1rem;
    margin-right: -1rem;

    iframe {
        height: 250px !important;
        width: 100%;
    }
    @include media-breakpoint-up(md) {
        margin-left: 0;
        margin-right: 0;

        iframe {
            height: 300px !important;
            width: inherit;
        }
    }
    @include media-breakpoint-up(lg) {

        iframe {
            height: 400px !important;
        }
    }
}

.body-locked {
    height: 100%;
    overflow: hidden;
}

.overlay {
    background: rgba(0, 0, 0, .3);
    opacity: 1;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    overflow-y: scroll;
}

.scroll__btn {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    background: $white-color;
    border: $lightgrey-color-2 solid 1px;
    border-radius: 50%;
    width: 2.9rem;
    height: 2.9rem;
    z-index: 10;
    display: none;

    span {
        position: relative;
        display: block;
        height: 2.9rem;

        &:before {
            @include after-before-abs;
            top: 50%;
            left: 50%;
            margin: -5px 0 0 -7px;
            width: 14px;
            height: 10px;
            background-size: 14px 10px;
            background: url(../svg/up_l.svg)  center center  no-repeat;
        }
    }
    @include media-breakpoint-up(lg) {
        width: 3.5rem;
        height: 3.5rem;

        span {
            height: 3.5rem;

            &:before {
                width: 18px;
                height: 10px;
                margin: -5px 0 0 -9px;
                background-size: cover;
            }
        }
    }	
}

.main-about {
    color: $grey-color;
    padding: 1.3rem 0;
    display: none;
    @include media-breakpoint-up(lg) {
        display: block;
        padding: 1.2rem 0 2.4rem 0;
    }
}

.main-about__wrap {
    @include media-breakpoint-up(lg) {
        display: flex;
    }
}

.main-about__body-full {
    display: none;
}

.about__plus {
    @include flex-wrap;
    padding: 1.3rem 0 0 0;
    @include media-breakpoint-up(lg) {
        padding: 2.4rem 0 0 0;
        text-align: center;
    }	
}

.about__plus-item {
    width: calc(50% - .5rem);
    margin: 0 .5rem 1rem 0;

    &:nth-child(2n) {
        margin: 0 0 1rem .5rem;
    }
    @include media-breakpoint-up(lg) {
        width: 25%;
        margin: 0;
        padding: 0 1rem;
        background: url(../svg/stripe_v.svg) right repeat-y;

        &:nth-child(2n) {
            margin: 0;
        }

        &:last-child {
            background: none;
        }
    }
    @include media-breakpoint-up(xxl) {

        .main-about & {
            padding: 0 2rem;
        }
    }
}

.about__plus-icon {
    width: 3rem;
    height: 3rem;
    margin: 0 0 .5rem 0;
    display: flex;
    @include media-breakpoint-up(lg) {
        @include flex-justify-self-center;
        margin: 0 auto .5rem auto;
    }
}

.about__plus-title {
    color: $grey-color;
    font-weight:700;
    font-size: 1.1rem;
    line-height: 1.3;
    margin-bottom: .5rem;
    @include media-breakpoint-up(lg) {
        font-size: 1.3rem;
    }
    @include media-breakpoint-up(xlg) {
        padding: 0 2rem;

        .content & {
            padding: 0;
        }
    }
}

.content-wrap {
    @include media-breakpoint-up(lg) {
        display: flex;
    }
}

.content {
    padding: 0 0 1.5rem 0;
    @include media-breakpoint-up(lg) {
        padding: 0 0 2rem 0 ;
        min-height:calc(100vh - 395px);
    }
}

.content-nav {
    .rubric__title {
        &:after {
            display: none;
        }
    }
    @include media-breakpoint-up(lg) {
        width: 25%;
    }
    @include media-breakpoint-up(xlg) {
        width: 20%;
    }
}
.nav-sample {
    padding:0;
    margin:0;
    list-style:none;
    font-size:1.07rem;
    > li {
        width:100%;
        > a {
            display:block;
            position:relative;
            border-bottom: 1px solid $lightgrey-color-2;
            padding:.6rem 1rem;
            color:$grey-color;
            font-weight:700;
            @include hover-focus {
                color:$grey-color;
                background:$lightgrey-color-2;
            }
        }
        &.active > a {
            color:$blue-color;
            background:$white-color;
            @include hover-focus {
                color:$blue-color;
                background:$lightgrey-color-2;
            }
        }
    }
}
.catalog-inner {
    @include media-breakpoint-up(lg) {
        width: 75%;
        padding-left: 1.5rem;
    }
    @include media-breakpoint-up(xlg) {
        width: 80%;
    }
}

.content-inner {
    @include media-breakpoint-up(lg) {
        width: 78%;
        padding-left: 4rem;
    }
    @include media-breakpoint-up(xlg) {
        font-size: 1.07rem;
    }
}

.prompt {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0px .25rem 1rem rgba(0, 0, 0, 0.08);
    display: none;
    z-index: 100;
    color: $white-color;
    border-radius: .2rem;
    font-size: .75rem;
    line-height: 1.3;
    text-align: center;
    padding: .5rem;
    min-width: 5.5rem;

    &:after {
        @include after-before-abs;
        border-style: solid;
        border-color: transparent;
    }

    &.bottom {
        right: 0;
        top: 100%;
        margin-top: .4rem;

        &:after {
            top: -.4rem;
            right: .8rem;
            border-width: 0 .4rem .4rem .4rem;
            border-bottom-color: rgba(0, 0, 0, 0.5);
        }
    }

    &.top {
        right: 0;
        bottom: 100%;
        margin-left: -4.7rem;

        &:after {
            bottom: -.4rem;
            right: .5rem;
            border-width: .4rem .4rem  0 .4rem;
            border-top-color: rgba(0, 0, 0, 0.5);
        }
    }

    &.left {
        right: 100%;
        top: 50%;
        margin-top: -13px;
        margin-right: .5rem;

        &:after {
            right: -.4rem;
            top: 50%;
            margin-top: -.4rem;
            border-width: .4rem  0.4rem .4rem ;
            border-left-color: rgba(0, 0, 0, 0.5);
        }
    }

    &.right {
        left: 100%;
        top: 50%;
        margin-top: -1.3rem;
        margin-left: .5rem;

        &:after {
            left: -.4rem;
            top: 50%;
            margin-top: -.4rem;
            border-width: .4rem .4rem .4rem 0;
            border-right-color: rgba(0, 0, 0, 0.5);
        }
    }

    &.white-bg {
        background: $white-color;
        color: $grey-color;
        box-shadow: 0px .25rem 1rem rgba(0, 0, 0, 0.08);

        &.bottom {

            &:after {
                border-bottom-color: $white-color;
            }
        }

        &.top {

            &:after {
                border-top-color: $white-color;
            }
        }

        &.left {

            &:after {
                border-left-color: $white-color;
            }
        }

        &.right {

            &:after {
                border-right-color: $white-color;
            }
        }
    }

    button:hover > &, div:hover > &, a:hover > & {
        display: block;
    }

    span {
        display: block;
        position: relative;
    }
    @include media-breakpoint-up(lg) {
        width: 160px;

        &.bottom {
            left: 50%;
            margin-left: -80px;

            &:after {
                left: 50%;
                right: auto;
                top: -.4rem;
                margin-left: -.4rem;
            }
        }

        &.top {
            left: 50%;
            margin-left: -80px;

            &:after {
                left: 50%;
                right: auto;
                botttom: -.4rem;
                margin-left: -.4rem;
            }
        }
    }
}

.prompt-close {
    position: absolute;
    top: 5px;
    right: 5px;
    color: $text-color;
    font-size: 1;
}

.help {
    cursor: pointer;
    display: inline-block;
    margin-left: .3rem;
    background: rgba(33, 102, 143, 0.3);
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    position: relative;
    color: $white-color;
    > div {
        width: 12rem;
        font-size: .8rem;
    }
}

.form__date {
    margin: 0 -1rem;
    background: $lightgrey-color;
    padding-top: 1rem;

    h2 {
        padding: .5rem 1rem 0 1rem;
        margin: 0 0 .5rem 0;
    }

    @include media-breakpoint-up(lg) {
        margin: 0;
        border-radius: .4rem;
        padding-top: 1rem;

        h2 {
            padding: 1rem 2rem 0 2rem;	
        }
    }
}

.form__block {
    padding: .5rem 0 .5rem 1rem;
    margin-bottom: .5rem;
    border-bottom: 1px solid $lightgrey-color-2;

    &:last-child {
        border: none;
        margin: 0;
    }

    &.text-center {
        padding-left: 0;
    }
    @include media-breakpoint-up(lg) {
        display: block;
        padding: 1rem 0 1rem 2rem;
        margin-bottom: 1rem;
    }
}

.form__date-title {
    font-size: 1.1rem;
    line-height: 1.3;
    font-weight:700;
    color: $grey-color;
    margin-bottom: .8rem;
    @include media-breakpoint-up(xl) {
        margin-bottom: 1rem;
    }
}

.form__row {
    @include flex-wrap;
    align-items: flex-start;

    > div {
        margin: 0 1rem 1rem 0;

        .form-label {
            margin-bottom: 0;
        }

        &.form__date-title {
            align-self: center;
            margin-bottom: .6rem;
        }

        &.checkbox, &.radio {
            align-self: center;
            width: 100%;
            margin-bottom: 0;
        }

        &.form__btn {
            margin-top: 1rem;
            width: 100%;
        }
    }

    .btn {
        display: block;	
    }
    @include media-breakpoint-up(lg) {

        > div {
            margin: 0 2rem 1rem 0;

            &.checkbox, &.radio {
                width: auto;
                margin-bottom: 1rem;
            }

            &.form__date-title {
                padding: .5rem 0;
                margin-bottom: 1rem;
            }

            &.form__btn {
                flex: 1 1 auto;
                width: inherit;
                margin-top: 0;
            }
        }

        .form-label {
            margin-bottom: .3rem;
        }

        .btn {
            width: 100%;
            padding: 1.491em 3em;
        }
    }
}

.form__col {
    width: 100%;
    position: relative;

    .form-label {
        position: absolute;
        top: 1.1rem;
        left: 1.1rem;
        font-size: 1rem;
        pointer-events: none;
        color: $grey-color;
    }

    .form-control {
        padding: 1.6rem 1.1rem .6rem 1.1rem;

        &:focus + label,
        &:valid + label {
            transition: 0.2s ease all;
            top: .6rem;
            font-size: .7rem;
        }
    }
}

.form__col-mb {

    & + .form__col-mb {
        margin-top: 1rem;
    }
    @include media-breakpoint-up(lg) {

        & + .form__col-mb {
            margin-top: 1.2rem;
        }
    }
}

.form__col-2 {
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
        width: calc(100% / 2 - 1rem);
        padding: 0 1rem 0 0;

        &:last-child {
            padding: 0 0 0 1rem;
        }
    }
}

.form__col-3 {
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
        width: calc(100% / 2 - 2rem);
    }
    @include media-breakpoint-up(xl) {
        width: calc(100% / 3 - 2rem);
    }
}

.form__col-6 {
    width: calc(100% / 2 - 1rem);
    @include media-breakpoint-up(lg) {
        width: calc(100% / 2 - 2rem);
    }
    @include media-breakpoint-up(xl) {
        width: calc(100% / 6 - 2rem);
    }
}

.form__row-2 {
    margin-top: 1rem;
    @include flex-wrap;
    justify-content: center;

    > div {
        margin: 0 0 1rem 0;
    }
    @include media-breakpoint-up(lg) {
        margin-top: 1.5rem;

        > div {

            &:nth-child(1) {
                width: calc(40% - 1rem);
                margin: 0 1rem 0 0;
            }

            &:nth-child(2) {
                width: calc(30% - 1rem);
                margin: 0 0 0 1rem;
            }

            &:nth-child(3) {
                margin: 1rem 0 0 0;
            }
        }

        .btn {
            width: 100%;
            padding: 1.491em 3em;
        }
    }
}

.delete-button {
    background: url(../svg/delete.svg) center center no-repeat;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 50;
    position: absolute;
    top: 0;
    right: 0;
    @include hover-focus {
        background-image: url(../svg/delete_h.svg);
    }
}

.reg {
    @include media-breakpoint-up(lg) {
        width: 55%;
        margin: 0 auto;
        background: $lightgrey-color;
        border-radius: .4rem;
        padding: 2rem;
    }
    @include media-breakpoint-up(xl) {
        width: 45%;
        padding: 4rem;
    }
}

.feedback {
    background: $lightgrey-color;
    border-radius: .4rem;
    padding: 1rem;

    h2 {
        text-align: center;
    }
    @include media-breakpoint-up(lg) {
        max-width: 55%;
        padding: 2rem;
    }
    @include media-breakpoint-up(xl) {
        width: 25%;
        padding: 3rem;
    }
}

.buy {
    background: $lightgrey-color;
    border-radius: .4rem;
    padding: 1rem;

    .basket__item {
        padding: 0 0 1rem 0;
        margin: 0;
    }

    h2 {
        text-align: center;
    }
    @include media-breakpoint-up(lg) {
        width: 60%;
        margin: 0 auto;
        padding: 2rem;

        .basket__item {
            padding: 0 0 1.5rem 0;
        }
    }
    @include media-breakpoint-up(xl) {
        padding: 3rem;
    }
	@include media-breakpoint-up(xxl) {
		width: 42%;
	}
}
.modal__header {
    color: $grey-color;
    font-weight:700;
    font-size: 1.3rem;
    margin: 0 0 .7rem 0;
    text-align: center;
    @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
        margin: 0 0 1.6rem 0;
    }
}
