@charset "utf-8";
@import "./variables";
@import "./mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.profile {
    @include media-breakpoint-up(lg) {
        display: flex;
    }
}

.profile__body {

    .form__date {
        background: $white-color;
        padding-top: 0;
    }

    .form__block {
        border: none;
        margin-bottom: 0;
        padding-top: 0;
    }

    h2 {
        margin: 0;
    }
    @include media-breakpoint-up(lg) {
        flex: 1 0 66%;

        .form__date {
            padding-bottom: 1rem;
            background: $lightgrey-color;
        }

        .form__block {
            padding-top: 1rem;
            border-bottom: 1px solid $lightgrey-color-2;
            margin-bottom: 1rem;
        }

        h2 {
            font-size: 1.4rem;
        }
    }
}

.profile__block {
    padding: 0;
    @include media-breakpoint-up(lg) {
        width: 34%;
        padding: 0 0 0 2rem;
    }
    @include media-breakpoint-up(xl) {
        padding: 0 0 0 6rem;
    }
}

.profile__block-wrap {
    background: $lightgrey-color;
    padding: 1rem;
    margin: 1rem -1rem -1.5rem -1rem;
    border-bottom: 1px solid $lightgrey-color-2;

    .btn {
        display: block;
        width: 100%;
        max-width: 100%;
        font-size: 1rem;
        padding: .95rem;

        & + .btn {
            margin: .7rem 0 0 0;
        }
    }
    @include media-breakpoint-up(lg) {
        margin: 0;
        border-radius: .4rem;
        border: none;
        padding: 2rem;

        p {
            margin-bottom: 1.5rem;
        }
    }
}

.profile__block-title {
    color: $grey-color;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1;
    margin-bottom: .8rem;

    a {
        color: $grey-color;
        text-decoration: underline;
    }
    @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
        margin-bottom: 1.5rem;
    }
}

.order {
    margin: -.5rem 0 1rem 0;
    @include media-breakpoint-up(lg) {
        margin: 0 0 2rem 0;
    }
}

.order__item {
    padding: 1rem;
    color: $grey-color;
    border-bottom: $lightgrey-color-2 solid 1px;
    display: block;
    align-items: center;
    margin: 0 -1rem;
    @include hover-focus {
        color: $blue-color;
    }
    @include media-breakpoint-up(lg) {
        @include flex-between;
        padding: 1.3rem 0;
        margin: 0;
        align-self: center;
    }
}

.order__title {
    display: flex;
    align-items: center;
    font-size: 1rem;

    span {
        font-size: .95rem;
        color: $grey-color;
        margin-left: .3rem;
    }

    @include media-breakpoint-up(lg) {
        width: 42%;
        font-size: 1.1rem;
    }
}

.order__price__wrap {
    @include flex-between;
    align-items: center;
    align-self: center;
    @include media-breakpoint-up(lg) {
        width: 58%;
    }
}

.order__price {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 1rem;

    span {
        font-weight: 400;
        font-size: .95rem;
        color: $grey-color;
        margin-right: .3rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 1.1rem;
    }
}

.order__status {
    position: relative;
    color: $grey-color;

    &:before {
        @include after-before-inline;
        width: 10px;
        height: 13px;
        margin: -1px .5rem 0 0;
        vertical-align: middle;
    }

    &.blue {

        &:before {
            background-image: url("../svg/order-status-blue.svg");
        }
    }

    &.green {

        &:before {
            background-image: url("../svg/order-status-green.svg");
        }
    }

    &.red {

        &:before {
            background-image: url("../svg/order-status-red.svg");
        }
    }
}

.compare {
    margin-bottom: 1rem;

    .swiper-slide {
        width: 140px;
    }

    .slider-button-prev, .slider-button-next {
        display: none;
    }

    .swiper-container {
        margin: 0 -1rem 0 0;
        padding-left: 1px;
    }
    @include media-breakpoint-up(xs360) {

        .swiper-slide {
            width: 150px;
        }
    }
    @include media-breakpoint-up(lg) {
        @include flex-justify-start;
        margin-bottom: 1.5rem;

        .swiper-container {
            margin: 0;
        }

        .slider-button {
            top: -4.2rem;
        }

        .slider-button-prev {
            right: 4.5rem;
            display: flex;
        }

        .slider-button-next {
            display: flex;
        }

        .swiper-slide {
            width: 200px;
        }
    }
}

.compare__item {
    border: $lightgrey-color-2 solid 1px;
    margin-left: -1px;
    line-height: 1.3;

    .btn {
        padding: 0 .2rem;
        line-height: 30px;
        font-size: .8rem;
        width: 100%;
        min-width: inherit;
    }
}

.compare__header {
    display: none;
    width: 150px;
    border: $lightgrey-color-2 solid 1px;
    border-right: none;
    margin-right: -1px;
    font-size: .8rem;
    @include media-breakpoint-up(lg) {
        display: block;
    }
}

.compare__body {
    flex: 1 1 auto;
    @include media-breakpoint-up(lg) {
        width: calc(100% - 180px);
        position: relative;
    }
}

.compare__row {
    padding: .8rem;

    &:last-child {
        border-radius: 0 .4rem 0 0;

        img {
            border-radius: 0 .4rem 0 0;
        }
    }

    &:nth-child(2n) {
        background: $lightgrey-color;
    }

    &:nth-child(2) {
        background: $white-color;
        padding-bottom: 0;
    }

    .compare__header & {

        &:nth-child(2) {
            background: $lightgrey-color;
        }
    }

    &:nth-child(3) {
        padding-top: .6rem;
    }
    @include media-breakpoint-up(lg) {

        .compare__label {
            display: none;
        }
    }
}

.compare__title {
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    color: $grey-color;
    height: 30px;

    .compare__header & {
        font-size: .85rem;
        color: $text-color;
    }
    @include media-breakpoint-up(lg) {
        font-size: .9rem;
        height: 34px;
        text-align: left;
    }
}

.compare__value {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 19px;
    line-height: 19px;

    .compare__item & {
        color: $grey-color;
    }
}

.compare__label {
    font-size: .85rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.compare__price-header {
    height: 62px;
    line-height: 25px;
}

.compare__price {
    color: $text-color;
    font-size: 1.1rem;
    margin-bottom: .5rem;
    display: block;
    font-weight: 700;
    @include media-breakpoint-up(lg) {
        font-size: 1.3rem;
        height: 25px;
        margin-bottom: 7px;

        del {
            font-size: .9rem;
        }
    }

    del {
        font-weight: 400;
        font-size: .85rem;
        color: $grey-color;
        margin-right: .5rem;
    }
}

.compare__img {
    position: relative;
    display: block;
    margin: -.8rem;
    height: 139px;
    @include media-breakpoint-up(xs360) {
        height: 149px;
    }
    @include media-breakpoint-up(lg) {
        height: 199px;
    }
}

.compare__filter {
    margin: 0 0 1rem 0;
    @include media-breakpoint-up(lg) {
        @include flex-justify-start;
        margin: 0 0 1.6rem 0;
        align-items: center;

        .form-select {
            margin-left: 2rem;
            max-width: 15rem;
        }
    }
}

.compare__type {
    display: flex;
    line-height: 1.2;
    margin: 0 0 1rem 0;

    .sorting__button {

        &.active {
            padding-right: 0;
        }
    }
    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        align-self: center;
        margin: 0;
    }
}
