@charset "utf-8";
@import "./variables";
@import "./mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
.basket {
    @include media-breakpoint-up(lg) {
        display:flex;
    }
}
.basket__card {
    @include media-breakpoint-up(lg) {
        width:66%;
    } 
}
.basket__sum {
    padding:0;
    position: static;
    @include media-breakpoint-up(lg) {
        width:34%;
        padding:0 0 0 2rem;
    }
    @include media-breakpoint-up(xlg) {
        padding:0 0 0 6rem;
    }
}
.basket__product {
    margin:-.5rem 0 0 0;
    @include media-breakpoint-up(lg) {
        margin:0 0 3rem 0 ;
    }
}

.basket__item {
    padding: 1rem;
    border-bottom:$lightgrey-color-2 solid 1px;
    @include flex-between;
    align-items: flex-start;
    margin: 0 -1rem;
    @include media-breakpoint-up(lg) {
        padding:1.3rem 0;
        margin:0;
        align-items: center;
        align-self: center;
    }
}
.basket__img {
    min-width:5rem;
    max-width:5rem;
    border:$lightgrey-color-2 solid 1px;
    border-radius:.2rem;
    margin-right:1rem;
    position:relative;
    img {
        border-radius:.4rem;
    }
    @include media-breakpoint-up(lg) {
        margin-right:2rem;
        min-width:5.6rem;
        max-width:5.6rem;
    }
}
.basket__body {
    flex: 1 1 auto;
    position:relative;
    @include media-breakpoint-up(lg) {
        display:flex;
        align-items: center;
        align-self: center;
    }
}
.basket__title-wrap {
    line-height:1.3;
    color:$text-color;
	
    flex: 1 1 auto;
    display: block;
    padding-right:3rem;
    @include hover-focus {
        color:$blue-color;
    }
    @include media-breakpoint-up(lg) {
        margin-right:1rem;
        padding:0;
    }
    @include media-breakpoint-up(xl) {
        margin-right:2rem;
    }
}
.basket__title {
    font-size:1rem;
    margin-bottom:.3rem;
    @include media-breakpoint-up(lg) {
        font-size:1.1rem;
    }
}
.basket__code {
    font-size:.9rem;
    color:$grey-color;
}
.basket__count-wrap {
    @include media-breakpoint-up(lg) {
        min-width:8.7rem;
        max-width:8.7rem;
    }
}
.basket__count {
    @include flex-justify-start;
    button {
        border:$lightgrey-color-5 solid 1px;
        width:40px;
        height:40px;
        border-radius: .2rem 0 0 .2rem;
        position: relative;
        background:$white-color;
        &:before {
            @include after-before-abs;
            top:50%;
            left:50%;
            width:12px;
            height:2px;
            margin:-1px 0 0 -6px;
            background:$text-color;
        }
        &:last-child {
            border-radius:0 .2rem .2rem 0;
            &:after {
                @include after-before-abs;
                top:50%;
                left:50%;
                width:2px;
                height:12px;
                margin:-6px 0 0 -1px;
                background:$text-color;
            }
        }
        @include hover-focus {
            background:$blue-color;
            &:before, &:after {
                background:$white-color
            }	
        }
    }
    input {
        width:35px;
        line-height:38px;
        padding:0 .5rem;
        border-color:$lightgrey-color-5;
        border-radius:0;
        text-align:center;
        border-left:none;
        border-right: none;
    }
    @include media-breakpoint-up(xs360) {
        input {
            width:50px;
        }
    }
    @include media-breakpoint-up(lg) {
        @include flex-justify-items-center;
    }
}
.basket__price-wrap {
    @include flex-between;
    align-items: center;
    margin-top:.7rem;
    @include media-breakpoint-up(lg) {
        margin-top:0;
        display:flex;
        align-items: center;
        align-self: center;
    }
}
.basket__count-price {
    text-align:center;
    margin-top:.3rem;
    line-height:1.4;
    font-size:.9rem;
    color:$grey-color;
    del {
        color:$text-color;
        font-size:.75rem;
        margin-right:.3rem;
    }
}
.basket__price {
    color:$grey-color;
    align-self: center;
    align-items: center;
    justify-content: center;
    font-weight:700;
    font-size:1.2rem;
    line-height:1.4;
    text-align:right;
    del {
        font-weight:400;
        font-size:.9rem;
        color:$text-color;
        margin-right:.3rem;
        .basket__card & {
            display:block;
            margin:0
        }
    }
    @include media-breakpoint-up(lg) {
        margin:0 1rem;
        min-width:6.7rem;
        max-width:6.7rem;
        text-align:right;
        del {
            display:block;
        }
    }
}
.basket__delete {
    background-image:url(../svg/delete.svg);
    background-position:right top ;
    background-repeat:no-repeat;
    width:3rem;
    height:3rem;
    position:absolute;
    top:0;
    right:0;
    @include hover-focus {
        background-image:url(../svg/delete_h.svg)
    }
    @include media-breakpoint-up(lg) {
        position:relative;
        top:inherit;
        right:auto;
        background-position:center center;
        min-width:4rem;
        max-width:4rem;
        width:4rem;
        height:4rem;
    }
}
.basket__header {
    display:none;
    color: $grey-color;
    font-weight:700;
    font-size:1.1rem;
    border-top:none;
    border-bottom:$grey-color solid 1px;
    justify-content:flex-end;
    .basket__price {
        min-width:10.7rem;
        max-width:10.7rem;
        padding-right:4rem;
		
    }
    .basket__count-wrap {
        justify-content:flex-start;
    }
    .basket__title-wrap {
        margin-right:auto
    }
    @include media-breakpoint-up(lg) {
        display:flex;
    }
}

.basket__footer {
    color: $grey-color;
    font-weight:700;
    border:none;
    align-items: center;
    .basket__count-wrap {
        color:$red-color;
        justify-content:flex-end;
        align-self: flex-end;
        text-align:right;
        line-height: 1.6;
        padding-right:.7rem;
    }
    @include media-breakpoint-up(lg) {
        .basket__price {
            min-width:10.7rem;
            max-width:10.7rem;
            padding-right:4rem;
		
        }
        .basket__title-wrap {
            margin-right:auto
        }
        .basket__count-wrap {
            padding:0;
        }
    }
}



.basket__map {
    margin:0 1rem .5rem 0;
    border: 1px solid $lightgrey-color-2;
    @include media-breakpoint-up(lg) {
        margin:.5rem 2rem 1rem 0;
    }
}
.basket__sum-fix {
    background:$white-color;
    padding:1rem 0 0 0;
    .btn {
        display:block;
        width:100%;
        max-width:100%;
        font-size:1rem;
        padding:1rem;
    }
    @include media-breakpoint-up(lg) {
        background:$lightgrey-color;
        border-radius:.4rem;
        padding:2rem;
        .btn {
            padding:1.1rem 1rem;
        }	
    }	
}
.basket__sum-item {
    @include flex-between;
    align-items: center;
    align-self: center;
    margin-bottom:1rem;
    line-height:1.2;
    > div:first-child {
        position: relative;
        flex: 1 1 auto;
        &:before {
            @include after-before-abs;
            bottom:5px;
            left:0;
            right:0;
            height:1px;
            background:url(../svg/dotter-grey.svg) bottom repeat-x
        }
        span {
            position: relative;
            background:$white-color;
            z-index: 1;
            padding: 0 .3rem 0 0;
        }
        i {
            font-weight:400;
            font-style:normal;
            color:$grey-color;
        }
    }
    > div:last-child {
        white-space: nowrap;
        color:$grey-color;
        font-weight:700;
        margin-left: auto;
        padding-left:.3rem;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom:1rem;
        > div:first-child {
            span {
                background:$lightgrey-color;
            }
        }
    }
}
.basket__sum-title {
    color: $grey-color;
    font-weight:700;
    > div:first-child {
        font-size: 1.1rem;
        color:$grey-color;
    }
}
.basket__sum-footer {
    padding:1rem 0;
    color: $grey-color;
    font-weight:700;
    > div:first-child { 
    }
    > div:last-child {
        font-size: 1.2rem;
        line-height:1;
    }
}
