@charset "utf-8";
@import "./variables";
@import "./mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.main-blog {
    display: none;
    padding: 1.3rem 0 .2rem 0;
    @include media-breakpoint-up(lg) {

        h2 {

            &:before {
                background-image: url("../svg/public-icon.svg");
            }
        }
    }
    @include media-breakpoint-up(lg) {
        display: block;
        padding: 2.4rem 0;
    }
}

.blog__all {
    display: none;
    padding-right: 1.8rem;
    font-size: .85rem;
    text-transform: uppercase;
    color: $text-color;
    background: url("../svg/forward_l.svg") right 1px no-repeat;
    @include hover-focus {
        color: $blue-color;
    }
    @include media-breakpoint-up(lg) {
        display: flex;
        align-self: center;
    }
}

.main-blog__wrap {
    @include media-breakpoint-up(lg) {
        @include flex-between;
    }
}

.main-blog__inner {
    display: flex;
    @include media-breakpoint-up(lg) {
        padding: 1.8rem 0 0  0;
        position: relative;
        border-top: $lightgrey-color-2 solid 1px;

        &:after {
            content: "";
            position: absolute;
            display: block;
            top: -1px;
            right: 0;
            height: .2rem;
            width: 3.4rem;
            background: $blue-color;
        }
    }
}

.blog__item {
    width: calc(50% - .5rem);
    margin: 0 .5rem 1rem 0;
    color: $grey-color;

    &:nth-child(2n) {
        margin: 0 0 1rem .5rem;
    }
    @include hover-focus {
        color: $text-color;
    }
    @include media-breakpoint-up(lg) {
        width: calc(100% / 4 - 1.8rem);
        margin: 0  2.4rem 2.4rem  0;

        &:nth-child(2n) {
            margin: 0  2.4rem 2.4rem  0;
        }

        &:nth-child(4n) {
            margin: 0 0 2.4rem  0;
        }

        .main-blog__inner  & {
            display: flex;
            width: calc(50% - 2rem);
            margin: 0 2rem 0 0;

            &:nth-child(2n) {
                margin: 0 0 0 2rem;
            }
        }
    }
}

.blog__date {
    color: $grey-color;
    font-size: .8rem;
    margin: .5rem 0 0 0;

    .main-blog__inner  & {
        margin: 0 0 .5rem 0;
    }
    @include media-breakpoint-up(lg) {

        .blog__date-wrap & {
            margin: 0 0 0 1rem;
        }
    }
}

.blog__img {
    margin: 0 0 .7rem 0;

    img {
        border-radius: .2rem;
    }
    @include media-breakpoint-up(lg) {
        margin: 0 0 1rem 0;

        .main-blog__inner & {
            min-width: 8rem;
            margin: 0 1rem 0 0;
        }
    }
    @include media-breakpoint-up(xl) {

        .main-blog__inner & {
            min-width: 15rem;
            margin: 0 1.5rem 0 0;
        }
    }
}

.blog__title {
    color: $grey-color;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 1.3;
    margin-bottom: .5rem;

    .blog__item:hover & {
        transition: all ease-out .2s;
        color: $blue-color;
    }
}

.blog__anons {
    margin-bottom: .5rem;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
        margin-bottom: 1rem;
    }
}

.blog__more {
    font-size: .8rem;
    text-transform: uppercase;
    color: $grey-color;
    display: none;

    .blog__item:hover & {
        transition: all ease-out .2s;
        color: $blue-color;
    }
    @include media-breakpoint-up(lg) {
        display: block;
    }
}

.blog {
    border-top: 1px solid $lightgrey-color-2;
    padding: 1.3rem 0;
    @include media-breakpoint-up(lg) {
        padding: 2.8rem 0 1.8rem 0;
    }
}

.blog__wrap {
    @include flex-wrap;
}

.news-detail-share {
    display: table-cell;
}
